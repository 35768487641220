// PaymentForm.tsx
import React, {FormEvent, useEffect, useState} from 'react';
import {
    useStripe,
    useElements,
    CardElement,
    PaymentElement,
    Elements,
    ExpressCheckoutElement
} from '@stripe/react-stripe-js';
import {loadStripe, StripeCardElement} from "@stripe/stripe-js";
import {StripeProvider} from "../StripeProvider/StripeProvider";
import axios from "axios";
import {ReactComponent as Selected} from "../../../assets/images/svg/CheckboxSelected.svg";
import {ReactComponent as NotSelected} from "../../../assets/images/svg/CheckboxNotSelected.svg";
import UniversalPopup from "../Popup/Popup.component";
import LoaderComponent from "../../mobile/Loader/Loader.component";


interface PaymentFormProps {
    paymentMethodOrder: string[];
    buttonText?: string;
    withDiscount?: boolean;
    clientSecret: string;
    user: any;// Add a prop for paymentMethodOrder
    callback: (paymentIntent: any) => void;
    useLoader: boolean,
    reference: string,
    tax?: string
}

const stripePromise = loadStripe('pk_live_51NiHlLJtY2AkE08BWgAvBWy4YKFznzKJhHd69EfxbFJdSquIPqhEjVIIolLEG0Xg40SV3JuZNzwMPFz6nMc7kW6e00M2xjhSj1'); // Replace with your Stripe publishable key

const PaymentForm: React.FC<PaymentFormProps> = ({
                                                     buttonText = 'Complete booking',
                                                     withDiscount = true,
                                                     paymentMethodOrder,
                                                     clientSecret,
                                                     user,
                                                     callback,
                                                     useLoader,
                                                     reference,
                                                     tax=''
                                                 }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [agree, setAgree] = useState<any>(false)
    const [popup, setPopup] = useState<any>(null)
    const [loader, setLoader] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>('')
    const handleError = (error: any) => {
        console.log(error)
        setLoading(false)
        setError(error)
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            //         // Stripe.js has not yet loaded.
              return;
        }

        setLoader(true);

        // Trigger form validation and wallet collection
            const {error: submitError} = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        // Use the clientSecret and Elements instance to confirm the setup
        const response = await stripe.confirmPayment({
                        elements,
                        clientSecret,
                        redirect: 'if_required',
                        confirmParams: {
                            payment_method_data: {
                                billing_details: {
                                    name: `${user.name} ${user.lastName}`,
                                    email: `${user.email}`,
                                    phone: `${user.phone}`
                                },
                            },
                        }
                        // Uncomment below if you only want redirect for redirect-based payments
                        // redirect: "if_required",
                    });

        if (response.error) {
            handleError(response.error);
            let data = {
                                time: new Date().getTime(),
                                object: 'order',
                                event: 'Payment error',
                                log: [response]
            }
            await axios.post('https://gscoworking.com/api/common/save-error', data)

        }
        if (response?.paymentIntent?.status === 'succeeded') {
                        console.log(tax)
                        callback({...response, taxCalculation: tax})
                    } else {
                        let data = {
                            time: new Date().getTime(),
                            object: 'order',
                            event: 'Payment situation',
                            log: [response]
                        }
                        await axios.post('https://gscoworking.com/api/common/save-error', data)
                    }
    };

    // const handleSubmit = async (e: FormEvent) => {
    //     console.warn('submit begin')
    //     e.preventDefault();
    //     if (!agree) {
    //         setPopup({
    //             title: 'Please agree',
    //             text: 'Please agree with Payment policy',
    //             buttonText1: 'Ok',
    //             onButton1Click: () => {
    //                 setPopup(null)
    //             },
    //         });
    //         return
    //     }
    //     if (!stripe || !elements) {
    //         // Stripe.js has not yet loaded.
    //         return;
    //     }
    //     setLoader(true)
    //     //await elements.submit()
    //     const paymentElement = elements!.getElement('payment');
    //
    //     // @ts-ignore
    //     // const { paymentMethod, error } = await stripe.createPaymentMethod({
    //     //     type: 'card',
    //     //     payment_element: elements.getElement(PaymentElement),
    //     // });
    //
    //     if (error) {
    //         console.error(error);
    //         handleError(error)
    //         // Handle and display any errors to the user
    //     } else {
    //         // Send the paymentMethod ID to your server for processing
    //         // const paymentMethodId = paymentMethod.id;
    //         const response = await stripe.confirmPayment({
    //             elements,
    //             clientSecret,
    //             redirect: 'if_required',
    //             confirmParams: {
    //                 payment_method_data: {
    //                     billing_details: {
    //                         name: `${user.name} ${user.lastName}`,
    //                         email: `${user.email}`,
    //                         phone: `${user.phone}`
    //                     },
    //                 },
    //             }
    //             // Uncomment below if you only want redirect for redirect-based payments
    //             // redirect: "if_required",
    //         });
    //
    //         if (response.error) {
    //             handleError(response.error);
    //             let data = {
    //                 time: new Date().getTime(),
    //                 object: 'order',
    //                 event: 'Payment error',
    //                 log: [response]
    //             }
    //             await axios.post('https://gscoworking.com/api/common/save-error', data)
    //             return
    //         }
    //         if (response?.paymentIntent?.status === 'succeeded') {
    //             console.log(tax)
    //             callback({...response, taxCalculation: tax})
    //         } else {
    //             let data = {
    //                 time: new Date().getTime(),
    //                 object: 'order',
    //                 event: 'Payment situation',
    //                 log: [response]
    //             }
    //             await axios.post('https://gscoworking.com/api/common/save-error', data)
    //         }
    //     }
    // };

    useEffect(() => {
        if (stripe && elements && clientSecret) {
            // @ts-ignore
            //  const paymentElement = stripe.elements().create('', {
            //      clientSecret,
            //  });
            //  paymentElement.mount('#payment-element');
        }
    }, [clientSecret]);

    useEffect(() => {
    }, [])

    return (

        <form onSubmit={handleSubmit}>
            {/*<ExpressCheckoutElement options={{paymentMethodOrder: ['apple_pay', 'apple']}} onConfirm={()=>{}} />*/}
            <PaymentElement options={{
                wallets: {applePay: 'auto', googlePay: 'auto'},
                paymentMethodOrder: ['card', 'google', 'apple_pay']
            }}/>
            {user && user.discount && withDiscount && (
                <div className={'my-20'}>
                    <span
                        className={'text-14 sea-text mt-20 pt-20'}>{`You personal discount is ${user.discount}%`}</span>
                </div>
            )}

            <div className={'row align-items-start gap-10 mt-20'}>
                {agree && (
                    <Selected className={'pointer'} onClick={() => setAgree((old: any) => !old)}/>
                )}
                {!agree && (
                    <NotSelected className={'pointer'} onClick={() => setAgree((old: any) => !old)}/>
                )}

                <span style={{width: 400}}>I have read and agree to the <a className={'blue-text'} href={'/terms'}
                                                                           target={'_blank'}>Terms of use</a>, <a
                    className={'blue-text'} href={'/privacy'} target={'_blank'}>Privacy Policy</a> and <a
                    className={'blue-text'} href={'/cancellation'} target={'_blank'}>Cancellation policy</a>.</span>
            </div>
            <button type="submit" className={'red-button mt-20'} style={{marginInline: 'auto', border: 'none'}}>
                <span className={'red-button-text'}>{buttonText}</span>
            </button>
            {popup && (
                <UniversalPopup // This should be the Popup component from usePopup
                    title={popup.title}
                    text={popup.text}
                    onClose={() => setPopup(null)}
                    buttonText1={popup.buttonText1}
                    buttonText2={popup.buttonText2}
                    onButton1Click={popup.onButton1Click}
                    onButton2Click={popup.onButton2Click}
                />
            )}
            {loader && useLoader && (
                <LoaderComponent text={'Please wait. Your booking is processing'} loading={loading}>
                    <span>{error?.message}</span>
                    <div className={'red-button row justify-content-center align-items-center'} onClick={() => {
                        setLoader(false)
                        setLoading(true)
                        setError(null)
                    }}>Ok
                    </div>
                </LoaderComponent>
            )}
        </form>


    );
};


const StripePayment = (props: {
    useLoader: boolean,
    user: any,
    order: any,
    total: any,
    buttonText?: string,
    withDiscount?: boolean,
    callback: (paymentIntent: any) => void,
    reference: string
}) => {
    const options = {
        mode: 'payment',
        amount: 100,
        currency: 'eur',
        payment_method_types: ['card'],
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                    postalCode: 'never'
                }
            },
            country: 'never'
        }
    }
    const [clientSecret, setClientSecret] = useState<any>(null)
    const [taxCalculation, setTaxCalculation] = useState<any>(null)

    const getCurrentIp = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error('Ошибка при получении IP-адреса:', error);
            return '193.19.204.4';
        }
    };

    useEffect(() => {
        console.log(props); //test

        let amount = props.order?.data?.amount || props.total.amount;
        if (props.user && props.user.discount && props.withDiscount) {
            amount = Math.round(amount - amount * props.user.discount / 100);
        }

        const vatRate = 19; // Например, 19% для Кипра
        const vatAmount = (amount * vatRate / 100).toFixed(2);
        const email = props.user?.email || 'hello@gscoworking.com';
        options.amount = Number(amount + vatAmount)
        const fetchIpAndPostData = async () => {
            const ip = await getCurrentIp();
            console.log('Current IP:', ip);

            console.log(props.total.amount <= props.total.amountWithDiscount, props.total.amountWithDiscount > 0, props.total.amount <= props.total.amountWithDiscount, props.total.amountWithDiscount, props.total.amount);
            console.log(props.total.amount > props.total.amountWithDiscount ? {
                type: 1,
                amount: (props.total.amountWithDiscount).toFixed(2),
                vatAmount: (props.total.amountWithDiscount * vatRate / 100).toFixed(2),
                email,
                vatRate
            } : {
                type: 2,
                amount,
                vatAmount,
                email,
                vatRate
            });
            console.log('------', props.total.amount > props.total.amountWithDiscount ? (props.total.amountWithDiscount).toFixed(2) : amount)
            axios.post('https://gscoworking.com/api/orders/payment', props.total.amount > props.total.amountWithDiscount ? {
                amount: (props.total.amountWithDiscount).toFixed(2),
                vatAmount: (props.total.amountWithDiscount * vatRate / 100).toFixed(2),
                email,
                vatRate,
                reference: props.reference,
                ip
            } : {
                amount,//ty
                vatAmount,
                email,
                vatRate,
                reference: props.reference,
                ip
            }).then((answer: any) => {
                setClientSecret(answer.data.secret);
                console.log(answer.data);
                if (answer.data && answer.data.tax) {
                    setTaxCalculation(answer.data.tax);
                }
            }).catch(e => {
                console.log(e);
            });
        };

        fetchIpAndPostData();
    }, []);

    return (
        <>
            {clientSecret && (
                // @ts-ignore
                <Elements stripe={stripePromise} options={options}>
                    <PaymentForm withDiscount={props.withDiscount} buttonText={props.buttonText} paymentMethodOrder={[]}
                                 clientSecret={clientSecret} user={props.user} callback={props.callback} tax={taxCalculation}
                                 useLoader={props.useLoader} reference={props.reference}/>
                </Elements>
            )}
        </>
    )
}

export default StripePayment;
