import React, { Component, useRef } from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as ChevronLeft} from '../../../assets/images/svg/ChevronLeft.svg';
import {ReactComponent as ChevronRight} from '../../../assets/images/svg/ChevronRight.svg';
import styles from './Calendar.module.scss'
import stylesDark from './CalendarDark.module.scss'

let oneDay = 60 * 60 * 24 * 1000;
let todayTimestamp = Date.now() - (Date.now() % oneDay) + (new Date().getTimezoneOffset() * 1000 * 60);
let inputRef = React.createRef<HTMLInputElement>(); // Add explicit type for inputRef

interface DayDetails {
    date: number;
    day: number;
    month: number;
    timestamp: number;
    dayString: string;
}

interface MyDatePickerState {
    year: number;
    month: number;
    selectedDay: number;
    monthDetails: DayDetails[];
    showDatePicker: boolean;
}

interface MyDatePickerProps {
    handleDateSelect: (day: any) => void;
    handleCancelButton?: () => void;
    dark?: boolean;
    selected?: number;
}
const daysMap = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export default class Calendar extends Component<MyDatePickerProps, MyDatePickerState> {
    monthMap: string[];

    constructor(props: MyDatePickerProps) {
        super(props);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        this.state = {
            year,
            month,
            dark: this.props.dark,
            // @ts-ignore
            selectedDay: this.props.selected,
            monthDetails: this.generateCalendar(year, month),
            showDatePicker: false,
        };

        this.monthMap = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];
    }

    componentDidMount() {
        window.addEventListener('click', this.addBackDrop);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.addBackDrop);
    }

    addBackDrop = (e: MouseEvent) => {
        if (this.state.showDatePicker && inputRef.current && !ReactDOM.findDOMNode(inputRef.current)?.contains(e.target as Node)) {
            this.showDatePicker(false);
        }
    }

    showDatePicker = (showDatePicker = true) => {
        this.setState({ showDatePicker });
    }

    getDayDetails = (args: any) => {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        return {
            date: _date,
            day,
            month,
            timestamp,
            dayString: daysMap[day] // Access the dayString from the pre-initialized daysMap array
        };
    }

    getNumberOfDays = (year: number, month: number): number => {
        return 40 - new Date(year, month, 40).getDate();
    }

    generateCalendar(year: number, month: number) {
        const monthArray = [];
        let firstDay = new Date(year, month, 1).getDay();
        if (firstDay === 0) {
            firstDay = 6; // Если первый день - воскресенье, измените его на 6 (понедельник).
        } else {
            firstDay -= 1; // В противном случае уменьшите на 1, чтобы начать с понедельника.
        }
        const daysInPreviousMonth = new Date(year, month, 0).getDate();

        for (let i = daysInPreviousMonth - firstDay + 1; i <= daysInPreviousMonth; i++) {
            monthArray.push({
                date: i,
                day: (firstDay + i - daysInPreviousMonth - 1) % 7,
                month: -1,
                timestamp: new Date(year, month - 1, i).getTime(),
                dayString: daysMap[(firstDay + i - daysInPreviousMonth - 1) % 7],
            });
        }

        const daysInMonth = new Date(year, month + 1, 0).getDate();

        for (let i = 1; i <= daysInMonth; i++) {
            monthArray.push({
                date: i,
                day: (firstDay + i - 1) % 7,
                month: 0,
                timestamp: new Date(year, month, i).getTime(),
                dayString: daysMap[(firstDay + i - 1) % 7],
            });
        }
        console.log(monthArray)
        return monthArray;
    }

    isCurrentDay = (day: DayDetails): boolean => {
        return day.timestamp === todayTimestamp;
    }

    isSelectedDay = (day: DayDetails): boolean => {
        return day.date === this.state.selectedDay;
    }

    getDateFromDateString = (dateValue: string): { year: number, month: number, date: number } | null => {
        let dateData = dateValue.split('-').map(d => parseInt(d, 10));
        if (dateData.length < 3)
            return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return { year, month, date };
    }

    getMonthStr = (month: number): string => this.monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromTimestamp = (timestamp: number): string => {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth() + 1;
        let date = dateObject.getDate();
        return  (date < 10 ? '0' + date : date) + '.' + (month < 10 ? '0' + month : month) + '.' + dateObject.getFullYear();
    }

    setDate = (dateData: { year: number, month: number, date: number }): void => {
        let selectedDay = new Date(dateData.year, dateData.month - 1, dateData.date).getTime();
        this.setState({ selectedDay })
        // if (this.props.onChange) {
        //     this.props.onChange(selectedDay);
        // }
    }

    onDateClick = (day: any): void => {
        this.setState({ selectedDay: day.date });
        if (this.props.handleDateSelect && typeof this.props.handleDateSelect === 'function') {
            this.props.handleDateSelect(this.getDateStringFromTimestamp(day.timestamp));
        }
    }

    onOkClick = (day: any): void => {
        if (this.props.handleDateSelect && typeof this.props.handleDateSelect === 'function') {
            this.props.handleDateSelect(this.getDateStringFromTimestamp(day));
        }
    }

    onCancelClick = (): void => {
        if (this.props.handleCancelButton && typeof this.props.handleCancelButton === 'function') {
            this.props.handleCancelButton();
        }
    }


    setYear = (offset: number): void => {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({
            year,
            monthDetails: this.generateCalendar(year, month)
        })
    }

    setMonth = (offset: number): void => {
        let year = this.state.year;
        let month = this.state.month + offset;
        if (month === -1) {
            month = 11;
            year--;
        } else if (month === 12) {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.generateCalendar(year, month)
        })
    }

    renderCalendar = (): JSX.Element => {
        let days = this.state.monthDetails.map((day, index) => {
            return (
                <div className={`${this.props.dark ? stylesDark['c-day-container'] : styles['c-day-container']} ${day.month !== 0 && this.props.dark ? stylesDark['disabled'] : day.month !== 0 ? styles['disabled'] : ''}`}
                     key={index}>
                    <div className={`${this.props.dark ? stylesDark['cdc-day'] : styles['cdc-day']}`}>
                        <span onClick={() => this.onDateClick(day)} className={`${this.isCurrentDay(day) ? styles['highlight'] : this.isSelectedDay(day) ? styles['highlight'] : ''} `}>
                            {day.date}
                        </span>
                    </div>
                </div>
            )
        })

        return (
            <div className={this.props.dark ? stylesDark['c-container'] : styles['c-container']}>
                <div className={styles['cc-head']}>
                    {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map((d, i) => <div key={i} className={styles['cch-name']}>{d}</div>)}
                </div>
                <div className={styles['cc-body']}>
                    {days}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={this.props.dark ? stylesDark['mdp-container'] : styles['mdp-container']}>
                <div className={'row align-items-center justify-content-around'}>
                    <ChevronLeft className={'pointer'} onClick={() => this.setMonth(-1)} />
                    <div className={styles['mdpch-container']}>
                        <div className={this.props.dark ? stylesDark['month-name'] : styles['month-name']}>{this.getMonthStr(this.state.month)}</div>
                    </div>
                    <ChevronRight className={'pointer'} onClick={() => this.setMonth(1)} />
                </div>
                <div className={styles['mdpc-body']}>
                    {this.renderCalendar()}
                </div>
                <div style={{width: '100%', height: 1, color: 'lightgray', float: "left"}} />
                <div className={styles['mdpc-footer']}>
                    <div className={'row justify-content-end'}>
                        <span className={'text-button mr-5 pointer'} onClick={this.onCancelClick}>Cancel</span>
                        <span className={'text-button pointer'} onClick={()=>this.onOkClick(this.state.selectedDay)}>Ok</span>
                    </div>
                </div>
            </div>
        )
    }
}
