import React, {useEffect, useState} from 'react';
import styles from './RoomDetails.module.scss'
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {fetchBuildingsAsync, selectBuildingByRoomId, selectRoomById} from "../../../reducers/buildingSlice";
import MapComponent from "../../../components/common/Map/Map.component";
import TopBar from "../TopBar/TopBar";
import ImageSliderComponent from "../../common/ImageSlider/ImageSlider.component";
import DraftComponent from "../../common/Draft/Draft.component";
import {ReactComponent as RatingStar} from "../../../assets/images/svg/RedStar.svg";
import DynamicIcon from "../../common/DynamicIcon.component";
import BookingWidgetTestComponent from "./BookingWidgetTest.component";
import {saveBookedSlots} from "../../../reducers/bookingSlice";
import {RootState} from "../../../reducers/types";
import axios from "axios";
import PopupComponent from "../../common/Popup/Popup.component";


const ReviewItem = (props: {review: any}) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < Math.round(props.review.rating); i++) {
            stars.push(<RatingStar key={i} />);
        }
        return stars;
    };
    return (
        <div className={'col gap-10 mb-30'}>
            <div className={'row align-items-center justify-content-between'}>
                <span className={'menu-name-chosen'}>{`${props.review.author.name} ${props.review.author.lastName}`}</span>
                <div className={'row align-items-center gap-20'}>
                    <span>{props.review.date}</span>
                    <div className={'row align-items-center'}>
                        {renderStars()}
                    </div>
                </div>

            </div>
            <span className={'main-text'}>{props.review.review}</span>
        </div>
    )
}
const IncludesSection = (props: {includes: any[]}) => {

    return (
        <div className={'row wrap gap-20 mb-50 mt-30'}>
            {props.includes.map((item: any)=>(
                <div className={`row align-items-center gap-20 col-22 mr-40 ${styles['include-item']}`}>
                    <DynamicIcon iconName={item.icon} />
                    <span>{item.quantity && item.quantity > 1 ? '2 x' : ''} {item.name}</span>
                </div>
            ))}
        </div>

    )
}
const BookedWidget = (props: {room: any,  callback: any, date?: any, hours: any, handleClose: any }) => {
    return (
        <div style={{position: 'fixed', top: 0, bottom: 0, left: 0, zIndex: 999}}>
            <TopBar MiddlePart={props.room.title} backLink={`/rooms/${props.room._id}`} backPressHandler={()=>props.handleClose()}/>
            <BookingWidgetTestComponent date={props.date} hours={props.hours} callback={props.callback} room={props.room} />
        </div>
    )
}

const RoomDetailsPage = () => {
    const { id } = useParams<{ id: string }>();
    const room = useSelector(selectRoomById(id));
    const building = useSelector(selectBuildingByRoomId(id))
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const [crumbs, setCrumbs] = useState<any[]>([
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Studios',
            link: '/spots'
        }
    ])
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const [moreReviews, setMoreReviews] = useState<number>(room && room?.reviews && room.reviews.length > 3 ? 3 : room?.reviews.length)
    const [displayedReviews, setDisplayedReviews] = useState<any>([])
    const [displayBookingDialog, setDisplayBookingDialog] = useState<any>(false)
    const [showPopup, setShowPopup] = React.useState(false);
    const [showPopupText, setShowPopupText] = React.useState('');
    function increaseReviewsCount() {
        setMoreReviews(old=>old+3)
    }

    useEffect(() => {
        if (room?.reviews && room.reviews.length > 0) {
            // Use the slice method to get the desired reviews
            const displayedReviews = room.reviews.slice(0, moreReviews);
            setDisplayedReviews(displayedReviews);
        }
    }, [moreReviews, room?.reviews]);

    useEffect(() => {
        //@ts-ignore
        dispatch(fetchBuildingsAsync());
    }, [dispatch]);

    useEffect(()=>{
        if (room && building) {
            if (room.seo && room.seo.title && room.seo.description) {
                document.title = room.seo.title; // Устанавливаем заголовок страницы
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', room.seo.description); // Устанавливаем описание страницы
                }
            }

            setCrumbs(old=>([...old, {
                text: building.address,
                link: `/building/${building.id}`
            },{
                text: room.title,
                link: `/room/${id}`
            }]))
        }
        window.scroll({top: 0})
        return ()=> {
            document.title = 'GoSound: the first music coworking space in Cyprus'; // Устанавливаем заголовок страницы
            const metaDescription = document.querySelector('meta[name="description"]');
            if (metaDescription) {
                metaDescription.setAttribute('content', 'GoSound is a music spaces rental service with simple convenient booking system. Our studios are perfect for musicians, vocalists, DJs and music teachers.'); // Устанавливаем описание страницы
            }
        }
    },[])

    function handleBookWidgetCallback(data: any, amount: any) {
        let payload = {
            roomID: room._id,
            buildingID: building._id,
            date: new Date(),
            slots: data,
            userID: user?._id,
            amount: amount
        }
        let showAlert = false; // Переменная для отслеживания активации alert
        if (room.hasOwnProperty('schedule') && room.schedule["1"]) {
            const limitations = room.schedule["1"].limitations;
            if (limitations) {
                const { start, end, days } = limitations;

                // Проверяем каждый забронированный слот
                data.forEach((slot: any) => {
                    const slotDate = new Date(slot.date);
                    const dayOfWeek = slotDate.getDay();
                    const slotStart = slot.start;
                    const slotEnd = slot.end;
                    const slotDuration = slotEnd - slotStart;
                    console.log(slotDuration, slotStart, slotEnd, start, end)
                    console.log((slotStart >= start && slotEnd <= end), slotStart >= start, slotEnd <= end)
                    // Проверяем соответствие длительности слота и диапазона времени
                    if (!(slotStart >= start && slotEnd <= end)) {
                        // Если слот в допустимом диапазоне и его длительность 1 час
                        if (slotDuration === 1) {
                            setShowPopupText(`Minimum booking of 2 consecutive hours required after ${end}:00`);
                            showAlert = true
                            setShowPopup(true)
                            return
                        }
                    }
                });
            }
        } else {
            console.log("Расписание для текущей комнаты не задано.");
        }
        if (!showAlert) { // Запускаем следующие операции только если предупреждение не было показано
            dispatch(saveBookedSlots(payload));
            navigation('/confirmation');
        }
    }

    return (
        <>
            {(!building || !room) && (
                <TopBar backLink={`/`} />
            )}
            {(building && room) && (
                <TopBar backLink={`/building/${building.id}`} MiddlePart={room?.title} />
            )}

            <div className={styles.container}>
                {(!building || !room) && (
                    <TopBar backLink={`/`} />
                )}
                {building && room && (
                    <>

                        <div style={{marginLeft: -10}}>
                            <ImageSliderComponent images={room.images} withDots={true} width={'94vw'} height={380}/>
                        </div>
                        <div className={'relative col-24'}>
                            <div className={'col col-24 pa-0 ml-0'}
                                 style={{transform: `scale(0.64)`, position: 'absolute', top: -20, left: -75}}>
                                <DraftComponent current={room?._id} id={building?._id}/>
                            </div>
                        </div>
                        <div className={'row mt-300 align-items-center gap-20 mb-15'}>
                            <span className={'sea-text h3-text'}>{room.title}</span>
                            <span className={'spot-title'}>{room.subtitle}</span>
                        </div>
                        <span className={'spot-title mt-15'}>Include: </span>
                        <IncludesSection includes={room.includes}/>
                        <span className={'my-50'}>{room.description}</span>
                        {room.title === 'Rehearsal Point' && (
                            <p className={'mb-50'} style={{fontWeight: 'bold', color: 'red'}}>Your rehearsal after 14.00 must be at least 2 hours or more</p>
                        )}                        <div className={'red-button row align-content-center justify-content-center mt-40 pointer wrap'}
                             style={{width: '100%', marginInline: 'auto'}}
                             onClick={() => setDisplayBookingDialog(true)}>
                            <span className={'red-button-text'}>Book</span>
                        </div>
                        <div className={'row mt-25 mb-50'}>
                            {/*{room && (*/}
                            {/*    // <>*/}
                            {/*    //     <LeftColumnComponent  address={room?.address} includes={room?.includes} rating={room?.rating} reviews={room?.reviews} subtitle={room?.subtitle} title={room?.title} images={room?.images} description={room.description} />*/}
                            {/*    //     <RightColumnComponent  draft={building?.id} currentRoom={room} currentBuilding = {building}/>*/}
                            {/*    // </>*/}
                            {/*)}*/}

                        </div>
                        <div className={'col-24 col pa-0'}>
                            <span className={'spot-title mb-20'}>Reviews: </span>
                            {displayedReviews.length === 0 && (
                                <>
                        <span className={'mb-20 '}>There are no reviews yet.
                               <span className={'ml-10 blue-text pointer'} onClick={() => navigation('/private?tab=2')}>Leave the first one</span>
                        </span>
                                </>
                            )}
                            {displayedReviews.map((r: any) => (
                                <ReviewItem review={r}/>
                            ))}
                            <span className={'pointer blue-text'} onClick={increaseReviewsCount}>More reviews</span>
                        </div>
                        <div className={'mt-50'} style={{borderRadius: 8, overflow: 'hidden'}}>
                            <MapComponent height={150} openPopup={false}/>
                        </div>
                        {displayBookingDialog && (
                            <BookedWidget room={room} callback={handleBookWidgetCallback} hours={"1"}
                                          handleClose={() => {
                                              setDisplayBookingDialog(false)
                                              window.scroll({top: 0})
                                          }}/>
                        )}
                    </>
                )}
            </div>
            {showPopup && (
                <PopupComponent buttonText1={'Ok'} onButton1Click={() => setShowPopup(false)} title={'Incorrect booking'} text={showPopupText} onClose={()=>setShowPopup(false)} />
            )}
        </>

    );
};

export default RoomDetailsPage;
export {};
