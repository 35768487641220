import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import BreadcrumbsComponent from "../components/common/Breadcrumbs/Breadcrumbs.component";
import PageTitleComponent from "../components/common/PageTitle/PageTitle.component";
import {ReactComponent as Cards} from "../assets/images/svg/TypeCards.svg";
import {ReactComponent as TrackOn} from "../assets/images/svg/TrackOn.svg";
import {ReactComponent as TrackOff} from "../assets/images/svg/TrackOff.svg";
import {ReactComponent as NotSelected} from "../assets/images/svg/CheckboxNotSelected.svg";
import {ReactComponent as Selected} from "../assets/images/svg/CheckboxSelected.svg";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducers/types";
import {clearBooking} from "../reducers/bookingSlice";
import UniversalPopup from "../components/common/Popup/Popup.component";

import PaymentForm from "../components/common/PaymentForm/PaymentForm";
import {StripeProvider} from "../components/common/StripeProvider/StripeProvider";
import LoaderComponent from "../components/mobile/Loader/Loader.component";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;
import {fetchUserDataByIdAndDispatch} from "../reducers/authSlice";

const PaymentPage = () => {
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);

    const [crumbs, setCrumbs] = useState<any>([
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Studios',
            link: '/spots'
        }])
    const location = useLocation()
    const navigation = useNavigate()
    const data = location.state;
    const dispatch = useDispatch()
    const [saveCard, setSaveCard] = useState<any>(true)
    const [popup, setPopup] = useState<any>(null)
    const [agree, setAgree] = useState<any>(false)
    const [showAllCards, setShowAllCards] = useState<boolean>(false)
    const [displayedCards, setDisplayedCard] = useState<any[]>([])
    const [addFundsValue, setAddFundsValue] = useState<number>(0)
    const [addNewCardMode, setAddNewCardMode] = useState<boolean>(false)
    const [selectedCard, setSelectedCard] = useState<any>(null)
    const [paymentType, setPaymentType] = useState<string>('card')
    const [fundsMode, setFundsMode] = useState<number>(0)
    const initialCardNumber = '____ ____ ____ ____'; // Use underscores (_) as placeholders

    const [newBind, setNewBind] = useState({
        cardType: '',
        cardNumber: initialCardNumber,
        cardHolder: '',
        cardCVC: '',
        expirationDate: '',
        mainCard: false
    })
    const [userObject, setUserObject] = useState<any>(null)
    const [amount, setAmount] = useState<any>(0)
    const [amountWithDiscount, setAmountWithDiscount] = useState<any>(0)
    const [useLoader, setUseLoader] = useState(true)
    const [loader, setLoader] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)

    const [countdown, setCountdown] = useState(600); // 10 minutes in seconds

    useEffect(() => {
        axios.post('https://gscoworking.com/api/orders/check-slots', {...data.data}).then((answer: any)=>{
            console.log(answer.data)
            if(answer.data === false){
                window.location.href = '/rooms/'+data.data.roomID; // Redirect to the booking page after the countdown
            }
        })
        console.log('Payment Page', data);
        const interval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (new Date().getTime() - data.data.date.getTime() < 600000) {
                    return Math.round(600 - (new Date().getTime() - data.data.date.getTime())/1000);
                } else {
                    clearInterval(interval);
                    window.location.href = '/rooms/'+data.data.roomID; // Redirect to the booking page after the countdown
                    return 0;
                }
            });
        }, 1000); // Update countdown every second

        return () => clearInterval(interval); // Cleanup: clear interval on unmount
    }, []);

    // Format countdown in minutes and seconds
    const minutes = Math.floor(countdown / 60).toString().padStart(2, '0');
    const seconds = (countdown % 60).toString().padStart(2, '0');

    useEffect(() => {
        if (fromStore) {
            setUserObject(fromStore);
        }
    }, [fromStore]);

    useEffect(()=>{
        if (data && data.building && data.room) {
            setCrumbs((old: any)=>([{
                text: 'Main',
                link: '/'
            },
                {
                    text: 'Studios',
                    link: '/spots'
                },  {
                text: data.building.address,
                link: `/building/${data.building._id}`
            },
                {
                    text: data.room.title,
                    link: `/rooms/${data.room._id}`
                },
                {
                    text: 'Payment',
                    link: ``
                }
            ]))
            calculateOrderAmount()
        }
    }, [])

    useEffect(()=>{
        console.log(data)
    }, [data])

    async function calculateOrderAmount () {
        try {
            const answer = await axios.post('https://gscoworking.com/api/common/order/cost', {data: data.data, room: data.data.roomID, building: data.data.buildingID, promocode: data.promocode, user: userObject?._id})
            setAmount(answer.data.amount)
            setAmountWithDiscount(answer.data.discounted)
        } catch (e) {
            console.log(e)
        }
    }

    async function handleBookingComplete (response: any) {
        if (amount===0 || amountWithDiscount === 0) {
            setLoader(true)
            setLoading(true)
            setError(null)
        }
        const apiUrl = 'https://gscoworking.com/api/orders';
        console.log(149, data.taxCalculation)
        axios.post(apiUrl, {...data.data, offset: -180, promocode: data.promocode, paymentIntent: response?.paymentIntent, log: [{
                time: new Date(),
                event: 'Payment',
                content: response
            }], device: 'desktop', "tax_calculation": response?.taxCalculation})
            .then((response) => {
                dispatch(clearBooking())
                setLoader(false)
                setLoading(false)
                setError(null)
                navigation('/complete', {state: {data: response.data}})
                // You can perform any additional actions after placing the order here
            })
            .catch((error: any) => {
                setPopup({
                    title: 'Booking error',
                    text: error.response.data.error,
                    buttonText2: 'Ok',
                    onButton2Click: ()=>navigation('/')
                })
                setUseLoader(false)
                setLoading(false)
                setError(error)
                console.error('Error placing the order:', error.response.data.error);
            });
    }

    function verifyPaymentData() {
        if (selectedCard) {
            return null
        }
        if (!newBind.cardHolder || newBind.cardHolder.length<3) {
           return 'Please fill Cardholder name'
        }
        const cardNumberPattern = /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/;

        if (!cardNumberPattern.test(newBind.cardNumber)) {
            return 'Incorrect card number'
        }
        if (!verifyExpirationDate(newBind.expirationDate.replaceAll(' ', ''))) {
            return 'Incorrect expiration date'
        }
        const cvcPattern = /^\d{3}$/;
        if (!cvcPattern.test(newBind.cardCVC)) {
            return 'Incorrect CVC'
        }

        return null
    }

    function verifyExpirationDate(expirationDate: string) {
        const expirationDatePattern = /^(0[1-9]|1[0-2])\/(\d{2}|\d{4})$/;
        if (!expirationDatePattern.test(expirationDate)) {
            return false;
        }

        const today = new Date();
        const [month, year] = expirationDate.split('/');
        const inputYear = parseInt(year);
        const currentYear = today.getFullYear() % 100; // Extract last two digits
        return (
            parseInt(month) >= 1 &&
            parseInt(month) <= 12 &&
            (inputYear > currentYear ||
                (inputYear === currentYear && parseInt(month) >= today.getMonth() + 1))
        );
    }

    async function handleAddFunds() {
        if (fundsMode===0) {
            if (amountWithDiscount && amountWithDiscount > 0) {
                setAddFundsValue(Math.ceil(amountWithDiscount-userObject?.deposit?.totalAmount || 0))
            } else {
                setAddFundsValue(Math.ceil(amount-userObject?.deposit?.totalAmount || 0))

            }
            setFundsMode(1)
        }
        if (fundsMode===1) {
            setFundsMode(2)
        }
    }

    async function addFundsComplete(response: any) {
            // /api/deposit/buy
            const apiUrl = 'https://gscoworking.com/api/deposit/buy';
            let answer = await axios.post(apiUrl, {
                userId: userObject._id,
                amount: addFundsValue,
                tax: response?.taxCalculation,
                paymentIntentId: response.id
            }).catch((e:any)=>{
                console.log(e.message)
            })
            if (answer && answer.data) {
                // @ts-ignore
                dispatch(fetchUserDataByIdAndDispatch(userObject._id));
                setFundsMode(0)
                setPaymentType('deposit')
            }
    }

    async function handleDepositBookingComplete() {
        if (!agree) {
            setPopup({
                title: 'error',
                text: 'Please agree with our terms',
                buttonText2: 'Ok',
                onButton2Click: ()=>setPopup(null)
            })
            return
        }

            setLoader(true)
            setLoading(true)
            setError(null)


        const apiUrl = 'https://gscoworking.com/api/orders';
        axios.post(apiUrl, {...data.data, offset: -180, promocode: data.promocode, paymentIntent: {type: 'deposit', amount: amountWithDiscount > 0 && amount>amountWithDiscount ? amountWithDiscount : amount, depositState: userObject?.deposit?.totalAmount}, log: [{
                time: new Date(),
                event: 'Payment',
                content: {type: 'deposit', amount: amountWithDiscount > 0 && amount>amountWithDiscount ? amountWithDiscount : amount, depositState: userObject?.deposit?.totalAmount}
            }], device: 'desktop', typeOfPayment: 'deposit'})
            .then((response) => {
                dispatch(clearBooking())
                setLoader(false)
                setLoading(false)
                setError(null)
                navigation('/complete', {state: {data: response.data}})
                // You can perform any additional actions after placing the order here
            })
            .catch((error: any) => {
                setPopup({
                    title: 'Booking error',
                    text: error.response.data.error,
                    buttonText2: 'Ok',
                    onButton2Click: ()=>navigation('/')
                })
                setUseLoader(false)
                setLoading(false)
                setError(error)
                console.error('Error placing the order:', error.response.data.error);
            });
    }

    useEffect(()=>{
        if (userObject && userObject.paymentMethods) {
            let cards = userObject.paymentMethods.filter((x:any)=>x.mainCard)
            if (cards && cards.length > 0 && !selectedCard) {
                setSelectedCard(cards[0])
            }
            if (showAllCards) {
                setDisplayedCard(userObject.paymentMethods)
            } else {
                setDisplayedCard([cards[0]])
            }
            if (!userObject.paymentMethods || userObject.paymentMethods.length === 0 ) {
                setAddNewCardMode(true)
            }
        }
    }, [showAllCards, userObject])


return (
        <div className={'wrapper col gap-23'}>
            <BreadcrumbsComponent crumbs={crumbs} />
            <PageTitleComponent backLink={`/rooms/${data?.room?._id}`} title={'Payment'} />
            <div className={'row justify-content-start gap-100'}>
                <div className={'col col-10 gap-23'}>
                    <div className={'row align-items-center gap-15'}>
                        <span className={paymentType==='deposit' ? 'payment-type-inactive' : 'payment-type-active'} onClick={()=>setPaymentType('card')}>By card</span>
                        <span className={paymentType==='card' ? 'payment-type-inactive' : 'payment-type-active'} onClick={()=>setPaymentType('deposit')}>By deposit</span>
                    </div>
                    {paymentType==='card' && (
                        <>
                            <span className={'mt-30 spot-title'}>How would you like to pay?</span>
                            <Cards/>
                            <div
                                className={'row align-items-center gap-30 text-21 magenta-text bold justify-content-between'}>
                                <span>Subtotal:</span>
                                <div className={'row gap-10 align-items-center'}>
                                    {userObject && userObject.discount ? (
                                        <span className={'text-18 sea-text crossline'}>{amount}€</span>
                                    ) : null}
                                    <span>{amountWithDiscount}€</span>
                                </div>

                            </div>
                            <div
                                className={'row align-items-center gap-30 text-20 magenta-text bold justify-content-between'}>
                                <span className={'text-18'}>VAT 19%</span>
                                <div className={'row gap-10 align-items-center'}>
                                        <span
                                            className={'text-16'}>{Number((amountWithDiscount * 19 / 100).toFixed(2))}€</span>
                                </div>

                            </div>
                            <div
                                className={'row align-items-center gap-30 text-20 magenta-text bold justify-content-between'}>
                                <span className={'text-18'}>Total</span>
                                <div className={'row gap-10 align-items-center'}>
                                        <span
                                            className={'text-16'}>{amountWithDiscount + Number((amountWithDiscount * 19 / 100).toFixed(2))}€</span>
                                </div>

                            </div>

                            <div className={'sea-text text-22 bold'}>{minutes}:{seconds}</div>
                            <div className={'sea-text text-16'}>After the countdown, you will be redirected to the
                                booking page.
                            </div>
                            {amount && amount > 0 && amountWithDiscount !== 0 && (
                                <PaymentForm useLoader={useLoader} user={userObject && userObject._id ? userObject : {
                                    name: data.data.user.name,
                                    lastName: data.data.user.lastName,
                                    email: data.data.user.email,
                                    phone: data.data.user.phone
                                }} order={data} callback={handleBookingComplete} total={{
                                    amount,
                                    amountWithDiscount
                                }} reference={`Booking ${data?.room?.title}`}/>
                            )}
                            {amount === 0 || amountWithDiscount === 0 && (
                                <div className={'red-button mt-20 row align-items-center justify-content-center'}
                                     style={{marginInline: 'auto', border: 'none'}}
                                     onClick={() => handleBookingComplete(null)}>
                                    <span className={'red-button-text'}>Complete booking</span>
                                </div>
                            )}
                            {amount === 0 || amountWithDiscount === 0 && loader && useLoader && (
                                <LoaderComponent text={'Please wait. Your booking is processing'} loading={loading}>
                                    <span>{error?.message}</span>
                                    <div className={'red-button row justify-content-center align-items-center'}
                                         onClick={() => {
                                             setLoader(false)
                                             setLoading(true)
                                             setError(null)
                                         }}>Ok
                                    </div>
                                </LoaderComponent>
                            )}
                        </>
                    )}
                    {paymentType === 'deposit' && (
                        <div className={'col col-18 gap-10 align-items-start'}>
                            {(!userObject || !userObject.fa2) && (
                                <span className={'magenta-text title'} style={{fontSize: 22, fontWeight: '500'}}>Please turn on Two factor authorization in account settings</span>
                            )}
                            {(amountWithDiscount > 0 && amountWithDiscount > userObject?.deposit?.totalAmount) && (
                                <>
                                    {fundsMode === 0 && (
                                        <>
                                            <div className={'row align-items-center'}>
                                                <span className={'spot-title'}>Deposit balance: </span>
                                                <span
                                                    className={'magenta-text spot-title'}>&nbsp;{userObject?.deposit?.totalAmount || 0}€</span>
                                            </div>
                                            <span className={'text-14 magenta-text'}>
                                                Not enough funds on deposit. Add funds or switch to card payment
                                            </span>
                                            <div
                                                className={'red-button mt-20 row align-items-center justify-content-center'}
                                                style={{border: 'none'}} onClick={() => handleAddFunds()}>
                                                <span className={'red-button-text'}>Add funds</span>
                                            </div>
                                            <div className={'row align-items-end inactive gap-20 mt-20'}>
                                                <span className={'text-26 bold'}>Total: </span>
                                                <span className={'text-16 old-price crossline'}>{amount}€</span>
                                                <span className={'text-26 price sea-text bold'}>{amountWithDiscount}€</span>
                                            </div>
                                        </>
                                    )}
                                    {fundsMode === 1 && (
                                        <div className={'col gap-20'}>
                                            <div className={'row align-items-center'}>
                                                <span className={'spot-title'}>Deposit balance: </span>
                                                <span className={'magenta-text spot-title'}>&nbsp;{userObject?.deposit?.totalAmount || 0}€</span>
                                            </div>
                                            <span className={'magenta-text'}>Add at least {amountWithDiscount>0 ? Math.floor(amountWithDiscount - userObject?.deposit?.totalAmount) : Math.floor(amount - userObject?.deposit?.totalAmount)} € to complete the purchase</span>
                                            <div className={'row relative align-items-center'}>
                                                <input type={"number"} className={'dark-input'} value={addFundsValue} onInput={(e) => {
                                                    // Asserting that e.target is an HTMLInputElement
                                                    const target = e.target as HTMLInputElement;
                                                    // Now you can safely access the value property
                                                    console.log(target.value)
                                                    setAddFundsValue(Number(target.value));
                                                }}/>
                                                <span className={'text-22 bold'} style={{marginLeft: -30}}>€</span>
                                            </div>
                                            <span>Balance after replenishment {((userObject?.deposit?.totalAmount*1 || 0 )+ Number(addFundsValue)).toFixed(2)} €</span>
                                            <div className={'row align-items-end  gap-20 mt-20'}>
                                                <span className={'text-26 bold'}>Total: </span>
                                                <span className={'text-26 price sea-text bold'}>{addFundsValue}€</span>
                                            </div>
                                            <div className={'red-button mt-20 row align-items-center justify-content-center'} style={{border: 'none'}} onClick={()=>handleAddFunds()}>
                                                <span className={'red-button-text'}>Add funds</span>
                                            </div>
                                        </div>
                                    )}
                                    {fundsMode===2 && (
                                        <div className={'col gap-20'}>
                                            <div className={'row align-items-end gap-20 mt-20'}>
                                                <span className={'text-26 bold'}>Total: </span>
                                                <span className={'text-26 price sea-text bold'}>{addFundsValue}€</span>
                                                <span className={'text-16 price sea-text '}>+ VAT 19%</span>
                                            </div>
                                            <PaymentForm
                                                useLoader={useLoader}
                                                user={userObject && userObject._id ? userObject : {name: data.data.user.name,  lastName: data.data.user.lastName, email: data.data.user.email, phone: data.data.user.phone}} order={{data: {amount: addFundsValue}}}
                                                callback={addFundsComplete}
                                                total = {{
                                                    amount: addFundsValue,
                                                    amountWithDiscount: 0
                                                    }}
                                                buttonText={'Add funds'}
                                                withDiscount={false}
                                                reference={'Add funds to deposit'}
                                            />
                                            {loader && (
                                                <LoaderComponent text={'Please wait. Your booking is processing'} loading={loading} >
                                                    <span>{error?.message}</span>
                                                    <div className={'red-button row justify-content-center align-items-center'} onClick={()=>{
                                                        setLoader(false)
                                                        setLoading(true)
                                                        setError(null)
                                                    }}>Ok</div>
                                                </LoaderComponent>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            {((amountWithDiscount>0 && amountWithDiscount < userObject?.deposit?.totalAmount) || amount < userObject?.deposit?.totalAmount) && (
                                <div className={'col gap-20'}>
                                    <div className={'row align-items-center'}>
                                        <span className={'spot-title'}>Deposit balance: </span>
                                        <span className={'sea-text spot-title'}>&nbsp;{userObject?.deposit?.totalAmount || 0}€</span>
                                    </div>
                                    <div className={'row align-items-end gap-20 mt-20'}>
                                        <span className={'text-26 bold'}>Total: </span>
                                        <span className={'text-16 old-price crossline'}>{amount}€</span>
                                        <span className={'text-26 price sea-text bold'}>{amountWithDiscount}€</span>
                                    </div>
                                    <div className={'row align-items-start gap-10 mt-20'}>
                                        {agree && (
                                            <Selected className={'pointer'} onClick={()=>setAgree((old: any)=>!old)} />
                                        )}
                                        {!agree && (
                                            <NotSelected className={'pointer'} onClick={()=>setAgree((old: any)=>!old)} />
                                        )}

                                        <span style={{width: 400}}>I have read and agree to the <a className={'blue-text'} href={'/terms'} target={'_blank'}>Terms of use</a>, <a className={'blue-text'} href={'/privacy'} target={'_blank'}>Privacy Policy</a> and <a className={'blue-text'} href={'/cancellation'} target={'_blank'}>Cancellation policy</a>.</span>
                                    </div>
                                    <button  className={'red-button mt-20'} style={{marginInline: 'auto', border: 'none'}} onClick={handleDepositBookingComplete}>
                                        <span className={'red-button-text'}>Complete booking</span>
                                    </button>
                                    {loading && (
                                        <LoaderComponent text={'Please wait. Your booking is processing'} loading={loading} >
                                            <span>{error?.message}</span>
                                            <div className={'red-button row justify-content-center align-items-center'} onClick={()=>{
                                                setLoader(false)
                                                setLoading(true)
                                                setError(null)
                                            }}>Ok</div>
                                        </LoaderComponent>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={'col-10 col justify-content-center'}>
                    <span className={'spot-title'}>Go Sound Cancellation Policy:</span>
                    <p className={'text-14'}>Please be aware that once we've confirmed your appointment, we allocate specific time slots in our schedule to accommodate your needs. You have the flexibility to edit your booking up to 24 hours prior to the scheduled start time. </p>
                    <p className={'text-14'}>However, if you need to cancel your appointment with less than 24 hours' notice, a 100% cancellation fee will be applied.</p>
                    <p className={'text-14'}>To avoid incurring the cancellation fee, we kindly request that you provide us with at least 24 hours' notice if you need to cancel your booking.
                    </p>
                    <p className={'text-14'}>You have the option to cancel your booking through the 'Bookings' section in your personal account, or you can contact us via email or <a className={'blue-text pointer'} href={'https://t.me/gosound_coworking'}>Telegram</a>.
                    </p>
                    <p className={'text-14'}>Once the cancellation is processed, the refunded amount will be credited back to the card used for the initial payment.
                    </p>
                </div>

            </div>
            {popup && (
                <UniversalPopup // This should be the Popup component from usePopup
                    title={popup.title}
                    text={popup.text}
                    onClose={()=>setPopup(null)}
                    buttonText1={popup.buttonText1}
                    buttonText2={popup.buttonText2}
                    onButton1Click={popup.onButton1Click}
                    onButton2Click={popup.onButton2Click}
                />
            )}
        </div>
    );
};

export default PaymentPage;
export {};
