import React, {useEffect} from 'react';
import './assets/scss/App.scss'
import './App.css';
import MainPage from "./pages/Main.page";
import UserPage from "./pages/User.page";
import NotFoundPage from "./pages/NotFound.page";
import {BrowserRouter, Routes, Route, Link, Navigate, useNavigation, useNavigate} from 'react-router-dom';
import TopBarComponent from "./components/desktop/TopBar/TopBar.component";
import {Provider, useDispatch, useSelector} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer'; // Your root reducer
import { RootState } from './reducers/types';
import SearchResultPage from "./pages/SearchResult.page"; // Define RootState type
import AllRoomsPage from './pages/AllRooms.page';
import RoomDetailsPage from './pages/RoomDetails.page';
import AllSpotsPage from "./pages/AllSpots.page";
import BuildingPage from "./pages/Building.page";
import ConfirmationPage from "./pages/Confirmation.page";
import VerifyEmailPage from "./pages/VerifyEmail.page";
import {checkLocalStorageForLoggedInStatus, fetchUserDataByIdAndDispatch} from './reducers/authSlice';
import PrivatePage from "./pages/Private.page";
import { fetchBuildingsAsync } from './reducers/buildingSlice';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import MobileApp from "./components/mobile/App/MobileApp";
import FooterComponent from "./components/desktop/Footer/Footer.component";
import ContactsPage from "./pages/Contacts.page";
import PrivacyPage from "./pages/Privacy.page";
import TermsPage from "./pages/Terms.page";
import BookingCompletePage from "./pages/BookingComplete.page";
import PaymentPage from "./pages/Payment.page";
import PersonalInfoPage from "./components/mobile/Account/PesonalInfoPage";
import CancelationPolicyPage from "./pages/CancelationPolicy.page";
import HowItWorks from "./pages/hiw.page";
import AboutUsPageComponent from "./pages/AboutUs/AboutUsPage.component";
import TeachersPageComponent from "./components/desktop/Teachers/Teachers.page.component";
import BuyCertificatePage from "./pages/BuyCertificate.page"; // Import the device detection functions
import RouteChangeTracker from './components/RouteChangeTracker';


const store = configureStore({
    reducer: rootReducer,
});

// @ts-ignore
store.dispatch(checkLocalStorageForLoggedInStatus());
// @ts-ignore
store.dispatch(fetchBuildingsAsync());

function App() {

    const DesktopComponent = () => {
        const userFull = useSelector((state: RootState) => state.auth.userFull);
        const isAuthenticated = !!userFull?._id; // Check if _id exists
        const dispatch = useDispatch();
        const user = useSelector((state: RootState)=>state.auth.userFull)
        const navigate = useNavigate();

        useEffect(() => {
            console.log(user)
            if (user && user._id) {

                // @ts-ignore
                dispatch(fetchUserDataByIdAndDispatch(user._id));
            } else {
                // Обработка ситуации, когда данные пользователя не найдены в localStorage
                // Например, редирект на страницу входа
            }

            console.log('Переход выполнен');
        }, [dispatch, navigate]);
        return (
          <>
              <TopBarComponent/>
              <RouteChangeTracker />
              <Routes>
                  <Route path="/" element={<MainPage/>}/>
                  <Route path="/about" element={<UserPage/>}/>
                  <Route path="/confirmation" element={<ConfirmationPage/>}/>
                  <Route path="/verify-email" element={<VerifyEmailPage/>}/>
                  <Route path="/search" element={<SearchResultPage/>}/>
                  <Route path="/spots" element={<AllSpotsPage/>}/>
                  <Route path="/rooms" element={<AllRoomsPage/>}/>
                  <Route path="/private" element={isAuthenticated ? <PrivatePage/> : <Navigate to="/"/>}/>
                  <Route path="/rooms/:id" element={<RoomDetailsPage/>}/>
                  <Route path="/building/:id" element={<BuildingPage/>}/>
                  <Route path="/contacts" element={<ContactsPage/>}/>
                  <Route path="/privacy" element={<PrivacyPage/>}/>
                  <Route path="/terms" element={<TermsPage/>}/>
                  <Route path="/complete" element={<BookingCompletePage/>}/>
                  <Route path="/payment" element={<PaymentPage/>}/>
                  <Route path="/cancellation" element={<CancelationPolicyPage/>}/>
                  <Route path="/how-it-works" element={<HowItWorks/>}/>
                  <Route path="/about-us" element={<AboutUsPageComponent/>}/>
                  <Route path="/partners" element={<TeachersPageComponent/>}/>
                  <Route path="/buy-certificate" element={<BuyCertificatePage/>}/>
                  <Route path="*" element={<NotFoundPage/>}/>
              </Routes>
              <FooterComponent/>
          </>
      )
    };

    const TabletComponent = () => (
        <>
            {/* Define the components for the tablet version */}
        </>
    );

    const MobileComponent = () => (
        <>
            <MobileApp />
        </>
    );

    return (
      <Provider store={store}>
      <BrowserRouter>
          {isDesktop ? (
              <DesktopComponent />
          ) : isTablet ? (
              <TabletComponent />
          ) : (
              <MobileComponent />
          )}
      </BrowserRouter>
      </Provider>
  );
}

export default App;

