import React, {useEffect, useState} from 'react';
import styles from './MyFunds.module.scss'
import {ReactComponent as CopyIcon} from '../../../assets/images/svg/CopyWhite.svg';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../../../reducers/authSlice";
import DepositeCardComponent from "./DepositeCard.component";
import PaymentForm from "../../common/PaymentForm/PaymentForm";
import {a} from "react-spring";
import axios from "axios";

const MyFundsComponent = () => {
    const menuItems = [
        'Add funds',
        'Withdrawal',
        'Cashback statistics',
        'Payment history'
    ]
    const packages = [
        {
            _id: '1',
            amount: 100,
            bonus: 10,
            type: 'percentage'
        },
        {
            _id: '2',
            amount: 300,
            bonus: 15,
            type: 'percentage'
        },
        {
            _id: '3',
            amount: 500,
            bonus: 20,
            type: 'percentage'
        },
        {
            _id: '4',
            amount: 1000,
            bonus: 20,
            type: 'percentage'
        }
    ]
    const dispatch = useDispatch()
    const fromStore = useSelector((state: RootState) => state.auth?.userFull);
    const [activeMenuItem, setActiveMenuItem] = useState<number>(0);
    //const [userObject, setUserObject] = useState<any>(null)
    const [referal, setReferal] = useState<any>(null);

    const [amount, setAmount] = useState<number>(1)
    const [selectedPackage, setSelectedPackage] = useState<string>('1')
    const [pageMode, setPageMode] = useState<string>('display')
    const [useLoader, setUseLoader] = useState(true)
    const [loader, setLoader] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
    const [referalStats, setReferalStats] = useState<any>(null)
    const [referalOrders, setReferalOrders] = useState<any[]>([])
    const [deposites, setDeposites] = useState<any[]>([])

    async function copyToClipboard(code: string) {
        try {
            await navigator.clipboard.writeText(code);
        } catch (error) {
            console.error('Не удалось скопировать текст: ', error);
        }
    }

    function handleDepositCardPress(deposit: any) {
        console.log('12222112')
        setSelectedPackage(deposit._id)
        setAmount(deposit.amount)
    }

    async function handleDepositBuy(paymentIntent: any) {
        setLoader(true)
        setLoading(true)
        setError(null)
        const apiUrl = 'https://gscoworking.com/api/deposit/buy';
        let answer = await axios.post(apiUrl, {
            userId: fromStore._id,
            amount: amount,
            tax: paymentIntent?.taxCalculation,
            paymentIntentId: paymentIntent.id
        }).catch((e:any)=>{
            setUseLoader(false)
            setLoading(false)
            setLoader(false)
            setError(error)
        })
        if (answer && answer.data) {
            setLoader(false)
            setLoading(false)
            setError(null)
            setUseLoader(false)
            setPageMode('display')
        }
    }

    const calculateBonusAmount = () => {
        const sortedPackages = [...packages].sort((a, b) => a.amount - b.amount);
        for (let i = 0; i < sortedPackages.length; i++) {
            if (i === sortedPackages.length - 1 || amount < sortedPackages[i + 1].amount) {
                if (amount >= sortedPackages[i].amount) {
                    const bonusPercentage = sortedPackages[i].bonus;
                    return amount * bonusPercentage / 100;
                }
            }
        }
        return 0;
    };

    useEffect(()=>{
        if (fromStore && fromStore.referalCode) {
            axios.get('https://gscoworking.com/api/promo-codes/get-code/' + fromStore.referalCode).then((r: any) => {
                setReferal(r.data)
            })
            axios.get(`https://gscoworking.com/api/referal-stat/${fromStore.referalCode}`).then((resp: any)=>{
                setReferalStats(resp.data)
                axios.get(`https://gscoworking.com/api/referal-orders/${fromStore.referalCode}`).then((orders: any)=>{
                    setReferalOrders(orders.data)
                })
            }).catch((e:any)=>{
                console.log(e.message)
            })
        }
        if (fromStore && fromStore._id) {
            axios.get(`https://gscoworking.com/api/deposit/user/${fromStore._id}`).then((deposites: any)=>{
                setDeposites(deposites.data.deposits)
            })

        }
    }, [])

    // useEffect(() => {
    //     if (fromStore) {
    //         setUserObject(fromStore);
    //     }
    // }, [fromStore]);

    useEffect(() => {
        // Диспатчим действие для проверки статуса входа в систему и загрузки данных пользователя с сервера
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus());
        // @ts-ignore
    }, [dispatch]);

    useEffect(()=>{
        if (amount===0 && packages && packages.length>0) {
            setAmount((packages.find((p: any) => p._id === selectedPackage)?.amount) ?? 0);
        }
    }, [])

    const formatDate = (dateString: string) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        // @ts-ignore
        return new Date(dateString).toLocaleDateString('ru-RU', options);
    };

    const formatDateHHmm = (dateString: string) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        // @ts-ignore
        return new Date(dateString).toLocaleString('ru-RU', options);
    };

    const Succeeded = () => (
        <span className={'sea-text text-14'}>Succeeded</span>
    )

    const Failed = () => (
        <span className={'magenta-text text-14'}>Succeeded</span>
    )


    return (
        <>
            {pageMode === 'display' && (
                <div className={`col pt-50 ${styles.container}`}>
                    {fromStore && fromStore.fa2 && (
                        <>
                            <div className={'row gap-40'}>
                                {menuItems.map((item:string, index: number)=>(
                                    <span className={`pointer spot-title ${styles['menu-item']} ${index===activeMenuItem ? styles.activeMenuItem : styles.inactiveMenuItem}`} onClick={()=>{setActiveMenuItem(index)}}>{item}</span>
                                ))}
                            </div>
                            {activeMenuItem===0 && (
                                <>
                                    <div style={{width: '100%', height: 1, background: '#525460'}} />
                                    <div className={`mt-30 row justify-content-between`}>
                                        <div className={`col col-8`}>
                                            <span className={`spot-title`}>Balance:</span>
                                            <span className={`text-price mt-20 sea-text`}>{Math.floor(fromStore.deposit.totalAmount)} €</span>
                                            <span className={`main-text mt-10`}><span className={'bold'}>{referalStats?.sum} €</span> Cashback Earned</span>
                                            <span className={`main-text mt-10`}><span className={'bold'}>0 €</span> Promo Purchases</span>
                                            <span className={`spot-title mt-60`}>My promocode:</span>
                                            <div className={`mt-20 relative ${styles.promo}`}>
                                                <CopyIcon className={`${styles.copyButton}`} onClick={()=>copyToClipboard(fromStore.referalCode)}/>
                                                <span className={`${styles.promocode}`}>{fromStore.referalCode}</span>
                                            </div>
                                            {referal && (
                                                <span className={'mt-5 sea-text'}>Your referral code: {referal.discount.profit.value}% cashback for you, {referal.discount.value}% off for
                                    others</span>
                                            )}
                                            {fromStore && fromStore.discount && (
                                                <div className={styles['discount-container']} style={{backgroundImage: `url(${require('../../../assets/images/discountBlockBackground.png')})`, backgroundPosition: 'right', backgroundRepeat: 'no-repeat'}}>
                                                    <span className={'text-16 sea-text'}>My discount</span>
                                                    <span className={'text-38 sea-text bold'}>{fromStore.discount}%</span>
                                                </div>
                                            )}



                                        </div>
                                        <div className={`col col-10 gap-10`}>
                                            <span className={`spot-title`}>Buy deposit:</span>
                                            <span>Buy any amount you want </span>
                                            <input className={`${styles.amountInput}`} value={amount} onFocus={()=>{setSelectedPackage('null')}} onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (Number(e.target.value)<1) {
                                                    return
                                                }
                                                setAmount(Number(e.target.value))
                                            }}/>
                                            {fromStore && fromStore.deposit && fromStore.deposit.totalAmount && (
                                                <span>Balance after replenishment {Number(fromStore.deposit.totalAmount*1 + Number(amount) + calculateBonusAmount()).toFixed(2)} €</span>
                                            )}
                                            <span className={`menu-name-chosen my-10`}>Or buy package:</span>
                                            {packages.map((pack: any)=>(
                                                <DepositeCardComponent  item={pack} currentItem={selectedPackage} handlePress={handleDepositCardPress}/>
                                            ))}
                                            <div className={`mt-20 ${styles['header-button']} red-button pointer row justify-content-center align-items-center`} style={{width: 320, boxShadow: 'none'}} onClick={()=>{
                                                if (Number(amount)<1) {
                                                    return
                                                }
                                                setPageMode('payment')
                                            }}>
                                                <span className={'red-button-text'}>Buy deposit</span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeMenuItem===2 && (
                                <div className={'col align-items-center gap-10'}>
                                    <div className={'col-24 row align-items-center justify-content-between'}>
                                        <div className={'col px-0 align-items-start'}>
                                            <span className={`text-white ${styles['promo-sea']}`}>Promocode:</span>
                                            <span className={`mt-20 sea-text ${styles['promo-sea']}`}>{fromStore.referalCode}</span>
                                        </div>
                                        <div className={'row mx-0'}>
                                            {/*<input className={styles.inp}/>*/}
                                            {/*<input className={`ml-20 ${styles.inp}`} style={{width: 180}}/>*/}
                                        </div>

                                    </div>
                                    <div className={'row col-24 justify-content-start align-items-center gap-30'}>
                                        <span>Created {formatDate(referalStats?.createdAt)}</span>
                                        <span>Active till {formatDate(referalStats?.expiryDate)}</span>
                                        <span>{referalStats?.discount}% discount</span>
                                        <span>{referalStats?.cashback}% cashback</span>
                                        <span>{referalStats?.q} purchased</span>
                                        <span>Cashback amount {referalStats?.sum} €</span>
                                    </div>
                                    <table cellSpacing={0} className={`mt-20 col-24`}>
                                        <thead className={`${styles.thead}`}>
                                        <th>User Name &
                                            Email</th>
                                        <th>Date &
                                            Time</th>
                                        <th>Room
                                            Name</th>
                                        <th>Order
                                            Amount</th>
                                        <th>Cashback
                                            Amount</th>
                                        </thead>
                                        <tbody>
                                        {referalOrders.map((order)=>(
                                            <tr>
                                                <td>{order.userID.name} {order.userID.lastnme}<br/>{order.userID.email}</td>
                                                <td>{formatDateHHmm(order.date)}</td>
                                                <td>{order.roomID.title}</td>
                                                <td>{order.amount} €</td>
                                                <td className={`sea-text bold`}>{parseFloat((order.amount*referalStats.cashback/100).toFixed(2))} €</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {activeMenuItem===3 && (
                                <div className={'col align-items-center gap-10'}>
                                    <table cellSpacing={0} className={`mt-20 col-24`}>
                                        <thead className={`${styles.thead}`}>
                                        <th>Date & Time</th>
                                        <th>Amount</th>
                                        <th>Operation Type</th>
                                        <th>Status</th>
                                        </thead>
                                        <tbody>
                                        {deposites.map((dep)=>(
                                            <tr>
                                                <td>{formatDateHHmm(dep.createdAt)}</td>
                                                {/*<td>{formatDateHHmm(order.date)}</td>*/}
                                                <td>
                                                    {dep.type === 'buy' && (
                                                        <span className={'text-14 sea-text'}>{dep.amount} €</span>
                                                    )}
                                                    {dep.type === 'booking' && (
                                                        <span className={'text-14 magenta-text'}>- {dep.amount} €</span>
                                                    )}
                                                    {dep.type === 'certificate' && (
                                                        <span className={'text-14 magenta-text'}>- {dep.amount} €</span>
                                                    )}
                                                    {dep.type === 'referal' && (
                                                        <span className={'text-14 sea-text'}>{dep.amount} €</span>
                                                    )}
                                                </td>
                                                <td>
                                                    {dep.type === 'buy' && (
                                                        <span className={'text-14'}>Deposit</span>
                                                    )}
                                                    {dep.type === 'booking' && (
                                                        <span className={'text-14'}>Space payment</span>
                                                    )}
                                                    {dep.type === 'certificate' && (
                                                        <span className={'text-14'}>Buy certificate</span>
                                                    )}
                                                    {dep.type === 'referal' && (
                                                        <span className={'text-14'}>Cashback</span>
                                                    )}

                                                </td>


                                                    <td>
                                                        {dep.status === 'approved' && (
                                                        <Succeeded />
                                                        )}
                                                        {dep.status === 'declined' && (
                                                            <Failed />
                                                        )}
                                                    </td>

                                                {/*<td className={`sea-text bold`}>{parseFloat((order.amount*referalStats.cashback/100).toFixed(2))} €</td>*/}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </>
                    )}
                    {!fromStore || !fromStore.fa2 && (
                        <div className={`center-content`}>
                            <span className={`sea-text center-content spot-title bold`}>Please turn on Two factor authorization in account settings and try again</span>
                        </div>
                    )}
                </div>
            )}
            {pageMode==='payment' && (
                <div className={'col mt-30'}>
                    <div
                        className={'row align-items-center gap-30 text-21 magenta-text bold justify-content-between'}>
                        <span>Subtotal:</span>
                        <div className={'row gap-10 align-items-center'}>
                            {/* Рассчитываем базовую сумму (сумму без НДС) */}
                            <span>{(amount / 1.19).toFixed(2)}€</span>
                        </div>
                    </div>
                    <div
                        className={'row align-items-center gap-30 text-20 magenta-text bold justify-content-between'}>
                        <span className={'text-18'}>VAT 19%</span>
                        <div className={'row gap-10 align-items-center'}>
                            {/* Рассчитываем НДС как 19% от базовой суммы */}
                            <span>{(amount / 1.19 * 0.19).toFixed(2)}€</span>
                        </div>
                    </div>
                    <div
                        className={'row align-items-center gap-30 text-20 magenta-text bold justify-content-between'}>
                        <span className={'text-18'}>Total</span>
                        <div className={'row gap-10 align-items-center'}>
                            {/* Общая сумма уже включает НДС и равна currentDeposit.amount */}
                            <span>{amount.toFixed(2)}€</span>
                        </div>
                    </div>
                    <PaymentForm withDiscount={false} buttonText={'Add Funds'} useLoader={true} user={fromStore}
                                 order={{amount: parseFloat((amount / 1.19).toFixed(2))}} total={{amount: parseFloat((amount / 1.19).toFixed(2)), amountWithDiscount: parseFloat((amount / 1.19).toFixed(2))}}
                                 callback={handleDepositBuy} reference={'Add funds'}/>
                </div>
            )}
        </>
    );
};

export default MyFundsComponent;
