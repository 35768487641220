import React, {useEffect, useRef, useState} from 'react';
import styles from './FavoritesOnMap.module.scss'
import MapComponent from "../../common/Map/Map.component";
import {GoogleMap, InfoWindow, LoadScript, Marker} from "@react-google-maps/api";
import OnMapInfoWindowComponent from "../../common/Map/OnMapInfoWindow.component";
import {ReactComponent as CloseIcon} from "../../../assets/images/svg/CloseIconWhite.svg";
import RoomCardComponent from "../SpotsPage/RoomCard/RoomCard.component";

const customMapStyle =[
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#3f4e66"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#e8e8e8"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#212121"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#bdbdbd"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#181818"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#1b1b1b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#92aad6"
            },
            {
                "weight": 5
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8a8a8a"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#92aad6"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#92aad6"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "weight": 1
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#3d3d3d"
            }
        ]
    }
]
interface MapPropsInterface {
    width?: string | number;
    height?: string | number;
    favorites?: any;
    openPopup?: boolean;
    closeClick: ()=>void;
}
const defaultCenter = {
    lat: 34.680573,
    lng: 33.047663,
};
const FavoritesOnMapComponent = ({
                                     closeClick = ()=> {},
                                     openPopup = true,
                                     height = '590px',
                                     width= '100%',
                                     favorites= [],
                                 }) => {
    const [selectedLocation, setSelectedLocation] = useState<any | null>(null);
    const [selectedRoom, setSelectedRoom] = useState<any | null>(null);
    const mapRef = useRef<any>(null);

    function getMapStyles() {
        return {
            height,
            width,
        }
    }

    function handleCloseClick() {
        closeClick()
    }

    const onMarkerClick = (location: any) => {
        setSelectedLocation(location);
    };

    const renderMarkers = () => {
        return favorites.map((fav: any) => (
            <Marker
                key={fav._id}
                position={{ lat: fav?.coords.lat, lng: fav?.coords.lng }}
                onClick={() => onMarkerClick(fav)}
            />
        ));
    };

    function handleBook () {
        console.log('handle book')
    }

    const onLoad = (map: any) => {
        mapRef.current = map;
        map.setZoom(15); // Set the initial zoom level once the map is loaded
    };

    useEffect(()=>{
        if (favorites && favorites.length>0) {
            setSelectedRoom(favorites[0])
        }
    }, [])

    return (
        <div className={`row gap-25 justify-content-between ${styles.container}`}>
            <div className={styles.mapWrapper}>
                <LoadScript googleMapsApiKey="AIzaSyBePGxNqTaL5HljGHwiLX13xXNYct7sM6k">
                    <GoogleMap mapContainerStyle={getMapStyles()} zoom={16} center={defaultCenter} options={{ styles: customMapStyle, fullscreenControl: false, mapTypeControl: false, streetViewControl: false }}  onLoad={onLoad} // Callback to get access to the map instance
                    >
                        {renderMarkers()}



                    </GoogleMap>
                </LoadScript>

            </div>
            {selectedLocation  && (
                <div className={`col gap-8 ${styles.infoWindowContainer}`}>
                    <RoomCardComponent room={selectedRoom} width={330} height={530} />
                    <div className={'row gap-8'}>
                        {favorites.map((f: any, idx)=>(
                            <div className={`col gap-5 ${styles.miniCard}  ${f._id === selectedRoom._id ? styles.selected : ''} `} onClick={()=>setSelectedRoom(f)}>
                                <img src={f.image}/>
                                <span className={'main-text text-black'}>{f.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FavoritesOnMapComponent;
export {};
