import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

// Asynchronous thunk for fetching orders
export const fetchOrdersAsync = createAsyncThunk(
    'orders/fetchOrdersAsync',
    async (userId: string, { dispatch }) => {
        try {
            const allOrdersResponse = await axios.get(`https://gscoworking.com/api/orders/user/${userId}?all=true`);
            const futureOrdersResponse = await axios.get(`https://gscoworking.com/api/orders/user/${userId}`);
            let prevOrders = allOrdersResponse.data.filter((order: { slots: { date: any; }[]; }) => {
                let orderDate = new Date(order.slots[0].date).setHours(0,0,0,0)
                let currentDate = new Date().setHours(0,0,0,0)
                if (orderDate < currentDate) {
                    return true
                }
            } )
            dispatch(setPrevOrders(prevOrders))
            dispatch(setOrders(allOrdersResponse.data));
            dispatch(setFutureOrders(futureOrdersResponse.data));
        } catch (error: any) {
            console.log(error);
            throw new Error('Error while fetching orders: ' + error.message);
        }
    }
);

export interface OrdersState {
    allOrders: any[];
    futureOrders: any[];
    prevOrders: any[];
    loading: boolean;
    error: any;
}

const initialState: OrdersState = {
    allOrders: [],
    futureOrders: [],
    prevOrders: [],
    loading: false,
    error: null,
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setFutureOrders: (state, action: PayloadAction<any>) => {
            state.futureOrders = action.payload;
        },
        setOrders: (state, action: PayloadAction<any>) => {
            state.allOrders = action.payload;
        },
        setPrevOrders: (state, action: PayloadAction<any>) => {
            state.prevOrders = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrdersAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrdersAsync.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchOrdersAsync.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Error while fetching orders';
            });
    },
});

export const { setOrders, setFutureOrders, setPrevOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
