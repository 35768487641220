import React, {useEffect, useState} from 'react';
import {ReactComponent as Off} from "../assets/images/svg/TrackOff.svg";
import {ReactComponent as On} from "../assets/images/svg/BlueSwitch.svg";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducers/types";
import {checkLocalStorageForLoggedInStatus} from "../reducers/authSlice";

const SettingsPage = () => {
    const userObject = useSelector((state: RootState)=>state.auth.userFull)
    const dispatch = useDispatch()
    const [settings, setSettings] = useState<any>({
        booking: {phone: false, email:false},
        discount: {phone: false},
        advertising: {phone: false}
    })
    const [fa2State, setFa2State] = useState<string>('none')
    const [fa2, setFa2] = useState<boolean>(false)
    const [fa2Code, setFa2Code] = useState<string>('')
    useEffect(()=>{
        if (userObject && userObject.settings) {
            setSettings(userObject.settings)
            setFa2(userObject.fa2)
        }
    }, [userObject])

    async function handleSaveChanges() {
        const updatedData = settings;

        const response = await axios.put(`https://gscoworking.com/api/user/${userObject._id}`, {settings: updatedData});
        if (response) {
            // @ts-ignore
            dispatch(checkLocalStorageForLoggedInStatus())
        }
    }

    async function toggleFa2() {
        console.log(fa2)
        if (!fa2) {
            if (fa2State==='none') {
                setFa2State('await')
                let answer: any = await axios.post('https://gscoworking.com/api/user/enable-fa2', {id: userObject._id})
                console.log(answer.data)
            }
            if (fa2State==='await') {
                setFa2Code('')
                setFa2State('none')
            }
        }
        if (fa2) {
            console.log('if fa2', userObject._id)
            setFa2(false)
            setFa2State('none')
            let answer: any = await axios.post('https://gscoworking.com/api/user/disable-fa2', {id: userObject._id})
            if (answer && answer.data) {
                // @ts-ignore
                dispatch(checkLocalStorageForLoggedInStatus())
            }

        }
    }

    useEffect(()=>{
        if (fa2Code && fa2Code.length===6) {
            let answer: any = axios.post('https://gscoworking.com/api/user/complete-fa2', {id: userObject._id, code: fa2Code}).then((answer:any)=>{
                if (answer && answer.data) {
                    // @ts-ignore
                    dispatch(checkLocalStorageForLoggedInStatus())
                }
            })
        }
    }, [fa2Code])

    return (
        <div className={'col col-7 mt-50 gap-20 relative'}>
            <span className={'spot-title'}>Security settings</span>
            <div className={'row justify-content-between align-items-center mb-40'}>
                <div className={'row col-24 justify-content-between'}>
                    <span className={'text-14'}>Two-factor authentication</span>
                    {fa2 && (
                        <On className={'pointer'} onClick={() => toggleFa2()}
                        />
                    )}
                    {!fa2 && (
                        <Off className={'pointer'} onClick={() => toggleFa2()}
                        />
                    )}
                </div>
                <div className={'row ml-50 align-items-center'} style={{position: "absolute", right: '-120%', maxWidth: '80%'}}>
                    {!fa2 && fa2State==='none' && (
                        <span className={'magenta-text'}>We’ve sent you a code to your email. Please enter it in the field below</span>
                    )}
                    {!fa2 && fa2State==='await' && (
                        <>
                            <span className={'magenta-text'}>We’ve sent you a code to your email. Please enter it in the field below</span>
                            <input className={'verification-input'} value={fa2Code} onInput={(e) => {
                                // Asserting that e.target is an HTMLInputElement
                                const target = e.target as HTMLInputElement;
                                // Now you can safely access the value property
                                console.log(target.value)
                                setFa2Code(target.value);

                            }}/>
                        </>
                    )}
                    {fa2 && (
                        <span className={'sea-text'}>Two-factor authentication successfully activited</span>
                    )}
                </div>
            </div>
            <span className={'spot-title'}>Notification settings</span>
            <span className={'main-text'} style={{fontWeight: 500}}>Booking info</span>
            <span className={'text-14'}>Receive information about your booking orders on e-mail or sms</span>
            <div className={'row justify-content-between align-items-center'}>
                <span>E-mail</span>
                {settings.booking.email && (
                    <On className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, booking: { ...old.booking, email: !old.booking.email } }))}
                    />
                )}
                {!settings.booking.email && (
                    <Off className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, booking: { ...old.booking, email: !old.booking.email } }))}
                    />
                )}
            </div>
            <div className={'row justify-content-between align-items-center'}>
                <span className={'text-14'}>Phone</span>
                {settings.booking.phone && (
                    <On className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, booking: { ...old.booking, phone: !old.booking.phone } }))}
                    />
                )}
                {!settings.booking.phone && (
                    <Off className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, booking: { ...old.booking, phone: !old.booking.phone } }))}
                    />
                )}
            </div>
            <span className={'main-text mt-40'} style={{fontWeight: 500}}>Discount info</span>
            <span className={'text-14'}>Receive information about your booking orders on e-mail or sms</span>
            <div className={'row justify-content-between align-items-center'}>
                <span className={'text-14'}>Phone</span>
                {settings.discount.phone && (
                    <On className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, discount: { ...old.discount, phone: !old.discount.phone } }))}/>
                )}
                {!settings.discount.phone && (
                    <Off className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, discount: { ...old.discount, phone: !old.discount.phone } }))}/>
                )}
            </div>
            <span className={'main-text mt-40'} style={{fontWeight: 500}}>Advertising</span>
            <span className={'text-14'}>Receive information about your booking orders on e-mail or sms</span>
            <div className={'row justify-content-between align-items-center'}>
                <span className={'text-14'}>Phone</span>
                {settings.advertising.phone && (
                    <On className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, advertising: { ...old.advertising, phone: !old.advertising.phone } }))}/>
                )}
                {!settings.advertising.phone && (
                    <Off className={'pointer'} onClick={() => setSettings((old: any) => ({ ...old, advertising: { ...old.advertising, phone: !old.advertising.phone } }))} />
                )}
            </div>
            <div className={'red-button row align-content-center justify-content-center mt-70 pointer'} onClick={handleSaveChanges}>
                <span className={'red-button-text'}>Save changes</span>
            </div>
        </div>
    );
};

export default SettingsPage;
export {};
