// MyFunctionalComponent.tsx
import React, {useEffect, useRef, useState} from 'react';
import styles from './BookingWidget.module.scss'; // Replace with your actual CSS module import
import {ReactComponent as CalendarIcon} from "../../../assets/images/svg/CalendarIconWhite.svg";
import {ReactComponent as ClockIcon} from "../../../assets/images/svg/ClockIconWhite.svg";
import {ReactComponent as InfoIcon} from "../../../assets/images/svg/InfoIconDark.svg";
import {ReactComponent as CloseIcon} from '../../../assets/images/svg/CloseIconOutline.svg';

import {useNavigate} from "react-router-dom";
import Calendar from "../../common/SearchComponent/Calendar.component";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import PopupComponent from "../../common/Popup/Popup.component";
import axios from "axios";
import socket from "../../../socket";

interface Slot {
    start: number;
    end: number;
    date: Date;
    amOrPm?: string;
}

interface RoomProps {
    date?: string;
    hours: number;
    multiple?: boolean;
    preselected?: any[];
    mode?: string;
    buttonText?: string;
    callback: (selectedHours: Slot[], amount: any) => void;
    room: {
        bookedSlots: { start: number; end: number; date: string }[];
        price: string;
        priceSuffix: string;
        oldPrice: string;
        minHours: number | null;
        defaultHours?: number;
        schedule?: any;
        _id: string;
    };
}


const MyFunctionalComponent: React.FC<RoomProps> = ({ date= null, hours, callback, room,  buttonText='Book', multiple=true, mode='new', preselected=[] }) => {
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const [selectOpen, setSelectOpen] = useState(false);
    const [selectedHours, setSelectedHours] = useState<Slot[]>(preselected && preselected.length>0 ? [{...preselected[0], date: new Date(preselected[0].date)}] : []);
    const [dates, setDates] = useState<{ formatted: string; clear: Date; dw: string }[]>([]);
    const [showGradient, setShowGradient] = useState(true);
    const navigation = useNavigate()
    const [showPopup, setShowPopup] = React.useState(false);
    const [showPopupText, setShowPopupText] = React.useState('');
    const [timeIntervals, setTimeIntervals] = useState<string[]>([
        '01 am - 02 am',
        '02 am - 03 am',
        '03 am - 04 am',
        '04 am - 05 am',
        '05 am - 06 am',
        '06 am - 07 am',
        '07 am - 08 am',
        '08 am - 09 am',
        '09 am - 10 am',
        '10 am - 11 am',
        '11 am - 12 pm',
        '12 pm - 01 pm',
        '01 pm - 02 pm',
        '02 pm - 03 pm',
        '03 pm - 04 pm',
        '04 pm - 05 pm',
        '05 pm - 06 pm',
        '06 pm - 07 pm',
        '07 pm - 08 pm',
        '08 pm - 09 pm',
        '09 pm - 10 pm',
        '10 pm - 11 pm',
        '11 pm - 12 am'
    ]);
    const daysMap = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const [selectedInterval, setSelectedInterval] = useState<string>('1');
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<any>(null)
    const [openCalendarInfo, setOpenCalendarInfo] = useState<any>(null)
    const [openHoursInfo, setOpenHoursInfo] = useState<any>(null)
    const { bookedSlots, price } = room;
    const hoursTableRef = useRef<HTMLDivElement>(null);

    const [countdown, setCountdown] = useState(300); // 10 minutes in seconds
    const [currentSocketId, setCurrentSocketId] = useState<any>(null)
    const [tmpBusySlots, setTmpBusySlots] = useState<any[]>([])


    const inSelected = (timeInterval: string, date: Date): string => {
        const timeRegex = /^(\d+)\s+-\s+(\d+)$/i;
        const [, startHourStr, endHourStr] = timeInterval.match(timeRegex) || [];
        if (!startHourStr || !endHourStr) {
            return ''; // Некорректный формат времени
        }

        const startHour = parseInt(startHourStr, 10);
        const endHour = parseInt(endHourStr, 10);

        const isInSelected = selectedHours.some(
            (slot) =>
                (slot.start === startHour && slot.end === endHour && slot.date.toDateString() === date.toDateString()) ||
                (slot.start === endHour && slot.end === startHour && slot.date.toDateString() === date.toDateString()) ||
                (slot.start === 0 && slot.end === 0 && startHour === 0 && endHour === 24 && slot.date.toDateString() === date.toDateString()) ||
                (slot.start === 24 && slot.end === 0 && startHour === 24 && endHour === 0 && slot.date.toDateString() === date.toDateString())
        );
        return isInSelected ? styles.selected : '';
    };
    useEffect(() => {
        socket.on('connect', () => {
            console.log('Socket connected');
            setCurrentSocketId(socket.id);

        });

        socket.on('initial', (msg) => {
            console.log('INITIAL', msg.id, socket.id);
            if (msg && msg.slots.hasOwnProperty(room._id)) {
                setTmpBusySlots(msg.slots[room._id])
            }
            //setCurrentSocketId(msg.id)
        });

        socket.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        socket.on('slot selected', (booking) => {
            console.log(socket.id)
            console.log('selected:', booking);
            if (booking && booking.hasOwnProperty(room._id)) {
                setTmpBusySlots(booking[room._id])
            }
        });

        socket.on('slot deselected', (booking) => {
            console.log('deselect:', booking);
            if (booking && booking.hasOwnProperty(room._id)) {
                setTmpBusySlots(booking[room._id])
            }
        });

        socket.on('update slots', (booking) => {
            console.log('deselect:', booking);
            if (booking && booking.hasOwnProperty(room._id)) {
                setTmpBusySlots(booking[room._id])
            }
        });

        socket.on('updateId', (msg) => {
            console.log(msg.id, socket.id)
            if (msg && msg.slots && msg.hasOwnProperty(room._id) && msg.id && msg.id===socket.id) {
                setTmpBusySlots(msg[room._id])
            }
        });

        socket.emit('update')

        return () => {
            if (socket) {
                //socket.disconnect(); // Отключаемся от сервера при размонтировании компонента
            }
        };
    }, []);
    useEffect(()=>{
        console.log(selectedHours)
    }, [selectedHours])
    // const selectSlot = (timeInterval: string, date: Date) => {
    //
    //     const timeRegex = /^(\d+)\s+(am|pm)\s+-\s+(\d+)\s+(am|pm)$/i;
    //     const [, startHourStr, startAmPm, endHourStr, endAmPm] = timeInterval.match(timeRegex) || [];
    //     if (!startHourStr || !startAmPm || !endHourStr || !endAmPm) {
    //         console.error('Неверный формат времени.');
    //         return;
    //     }
    //
    //     let startHour = parseInt(startHourStr);
    //     let endHour = parseInt(endHourStr);
    //
    //     if (startHour === 12) {
    //         startHour = startAmPm.toLowerCase() === 'am' ? 0 : 12;
    //     } else {
    //         startHour = startAmPm.toLowerCase() === 'pm' ? startHour + 12 : startHour;
    //     }
    //
    //     if (endHour === 12) {
    //         endHour = endAmPm.toLowerCase() === 'am' ? 0 : 12;
    //     } else {
    //         endHour = endAmPm.toLowerCase() === 'pm' ? endHour + 12 : endHour;
    //     }
    //
    //     const idx = selectedHours.findIndex(
    //         (slot) =>
    //             slot.start === startHour &&
    //             slot.end === endHour &&
    //             slot.date.toDateString() === date.toDateString() &&
    //             slot.amOrPm === startAmPm
    //     );
    //
    //     if (idx > -1) {
    //         setSelectedHours((prevSelected) => prevSelected.filter((_, index) => index !== idx));
    //     } else {
    //         setSelectedHours((prevSelected) => [...prevSelected, { start: startHour, end: endHour, date, amOrPm: startAmPm }]);
    //     }
    // };
    const selectSlot = (timeInterval: string, date: Date) => {
        if (!multiple) {
            setSelectedHours([])
            socket.emit('clear by id')
        }
        const timeRegex = /^(\d+)\s+-\s+(\d+)$/i;
        const [, startHourStr, endHourStr] = timeInterval.match(timeRegex) || [];
        if (!startHourStr || !endHourStr) {
            console.error('Неверный формат времени.');
            return;
        }

        const startHour = parseInt(startHourStr, 10);
        const endHour = parseInt(endHourStr, 10);

        const idx = selectedHours.findIndex(
            (slot) =>
                slot.start === startHour &&
                slot.end === endHour &&
                slot.date.toDateString() === date.toDateString()
        );

        if (idx > -1) {
            setSelectedHours((prevSelected) => prevSelected.filter((_, index) => index !== idx));
            socket.emit('slot deselected', {slotId: { start: startHour, end: endHour, date }, roomId: room._id})

        } else {
            setSelectedHours((prevSelected) => [...prevSelected, { start: startHour, end: endHour, date }]);
            socket.emit('slot selected', {slotId: { start: startHour, end: endHour, date }, roomId: room._id})
        }
    };
    const openSelect = () => {
        if (mode === 'edit') {
            return
        }
        setSelectOpen(true);
    };

    useEffect(()=>{
        generateDates()
    }, [currentDate])

    const generateDates = () => {
        // Generate an array of dates for the next five days
        let today = new Date();
        if (currentDate) {
            const [day, month, year] = currentDate.split('.').map(Number);

            const date = new Date(Date.UTC(year, month - 1, day));
            today = date;
        }
        const options = { day: 'numeric', month: 'short' };
        const datesArray = [];
        for (let i = 0; i < 5; i++) {

            const date = new Date(today.getTime() + i * 24 * 60 * 60 * 1000);
            // @ts-ignore
            const formattedDate = date.toLocaleDateString(undefined, options);

            datesArray.push({ formatted: formattedDate, clear: date, dw: daysMap[date.getDay()] });
        }
        setDates(datesArray);
    };

    // const generateTimeIntervals = () => {
    //     // Clear previous time intervals and generate new ones based on the selected interval
    //     setTimeIntervals((prevTimeIntervals) => {
    //         const intervals: string[] = [];
    //         const selectedIntervalHours = parseInt(selectedInterval, 10);
    //         const startTime = 0;
    //         const endTime = 24;
    //
    //         for (let hour = startTime; hour < endTime; hour += selectedIntervalHours) {
    //             const startHour = hour;
    //             const endHour = (hour + selectedIntervalHours) % 24;
    //             const amOrPmStart = startHour < 12 ? 'am' : 'pm';
    //             const amOrPmEnd = endHour < 12 ? 'am' : 'pm';
    //             const formattedStartHour = startHour === 0 ? 12 : startHour > 12 ? startHour - 12 : startHour;
    //             const formattedEndHour = endHour === 0 ? 12 : endHour > 12 ? endHour - 12 : endHour;
    //             let timeInterval = `${formattedStartHour} ${amOrPmStart}  - ${formattedEndHour} ${amOrPmEnd}`;
    //             if (amOrPmStart !== amOrPmEnd) {
    //                  timeInterval = `${formattedStartHour} ${amOrPmStart} - ${formattedEndHour} ${amOrPmEnd}`;
    //             }
    //             intervals.push(timeInterval);
    //         }
    //
    //         return intervals;
    //     });
    // };

    const generateTimeIntervals = () => {
        // Clear previous time intervals and generate new ones based on the selected interval
        setTimeIntervals((prevTimeIntervals) => {
            const intervals = [];
            const selectedIntervalHours = parseInt(selectedInterval, 10);
            const startTime = 8;
            const endTime = 23;

            for (let hour = startTime; hour < endTime; hour += selectedIntervalHours) {
                const startHour = hour;
                let endHour = (hour + selectedIntervalHours) % 24;
                const formattedStartHour = startHour.toString().padStart(2, '0');
                const formattedEndHour = endHour.toString().padStart(2, '0');
                const timeInterval = `${formattedStartHour} - ${formattedEndHour}`;
                if (endHour===0) {
                    endHour = 24
                }
                if (endHour <= 23) {
                    intervals.push(timeInterval);
                }
            }

            return intervals;
        });
    };

    function isDateBeforeToday(date: any) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight
        date.setHours(0, 0, 0, 0); // Set time to midnight
        return date < currentDate;
    }

    function isDateTomorrow(date: any) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight
        date.setHours(0, 0, 0, 0); // Set time to midnight
        return date > currentDate;
    }

    // const isBusyInterval = (timeInterval: string, date: Date) => {
    //     const timeMapping: any = {
    //         0: '12 am', 1: '1 am', 2: '2 am', 3: '3 am',
    //         4: '4 am', 5: '5 am', 6: '6 am', 7: '7 am',
    //         8: '8 am', 9: '9 am', 10: '10 am', 11: '11 am',
    //         12: '12 pm', 13: '1 pm', 14: '2 pm', 15: '3 pm',
    //         16: '4 pm', 17: '5 pm', 18: '6 pm', 19: '7 pm',
    //         20: '8 pm', 21: '9 pm', 22: '10 pm', 23: '11 pm'
    //     };
    //
    //     const reverseTimeMapping: any = {
    //         '12 am': 0, '1 am': 1, '2 am': 2, '3 am': 3,
    //         '4 am': 4, '5 am': 5, '6 am': 6, '7 am': 7,
    //         '8 am': 8, '9 am': 9, '10 am': 10, '11 am': 11,
    //         '12 pm': 12, '1 pm': 13, '2 pm': 14, '3 pm': 15,
    //         '4 pm': 16, '5 pm': 17, '6 pm': 18, '7 pm': 19,
    //         '8 pm': 20, '9 pm': 21, '10 pm': 22, '11 pm': 23
    //     };
    //
    //
    //
    //     const timeInterval24 = [reverseTimeMapping[timeInterval.split(' - ')[0].trim()], reverseTimeMapping[timeInterval.split(' - ')[1].trim()]]
    //
    //     if (timeInterval24[1] === 0) {
    //         timeInterval24[1] = 24
    //     }
    //     const [startHour, endHour] = timeInterval24;
    //     const busySlots = bookedSlots.filter((slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString());
    //     console.log(new Date('2023-09-15T10:00:00Z'))
    //
    //     if (new Date(date) < new Date('2023-09-11')) {
    //         return true
    //     }
    //     if (isDateBeforeToday(new Date(date))) {
    //         return true
    //     }
    //     if (startHour < new Date().getHours() && !isDateTomorrow(new Date(date))) {
    //         return true
    //     }
    //
    //     for (const slot of busySlots) {
    //         const slotStartHour = slot.start;
    //         const slotEndHour = slot.end;
    //
    //         if (date.toLocaleDateString() === new Date(slot.date).toLocaleDateString()) {
    //             if (
    //                 (startHour >= slotStartHour && startHour < slotEndHour) ||
    //                 (endHour > slotStartHour && endHour <= slotEndHour) ||
    //                 (slotStartHour >= startHour && slotStartHour < endHour) ||
    //                 (slotEndHour > startHour && slotEndHour <= endHour)
    //             ) {
    //                 return true;
    //             }
    //         }
    //     }
    //
    //     return false;
    // };
    const isBusyInterval = (timeInterval: string, date: Date) => {
        const timeRegex = /^(\d+)\s+-\s+(\d+)$/i;
        const [, startHourStr, endHourStr] = timeInterval.match(timeRegex) || [];
        if (!startHourStr || !endHourStr) {
            console.error('Неверный формат времени.');
            return true; // Return true to indicate an error since the time interval format is invalid.
        }

        const startHour = parseInt(startHourStr, 10);
        let endHour = parseInt(endHourStr, 10);

        if (endHour === 0) {
            endHour = 24;
        }
        //console.log(404, socket.id)
        const busySlots = [
            ...bookedSlots.filter((slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString()),
            ...tmpBusySlots.filter((slot) => new Date(slot.date).toLocaleDateString() === date.toLocaleDateString() && slot.client !== socket.id)
        ];
        if (room && room._id && (room._id === '64cd14e3b8821d6cf7d5e782' || room._id === '64cd154eb8821d6cf7d5e78c') && new Date(date) < new Date('2024-03-24')) {
            return true
        }

        if (isDateBeforeToday(new Date(date))) {
            return true;
        }

        if (startHour < new Date().getHours() && !isDateTomorrow(new Date(date))) {
            return true;
        }

        // if (room && room.hasOwnProperty('schedule')) {
        //     //console.log('schedule exist', selectedInterval)
        //     if (room.schedule.hasOwnProperty(selectedInterval)) {
        //         //console.log('time interval found')
        //         if (room.schedule[selectedInterval].limitations && room.schedule[selectedInterval].limitations.days) {
        //             //console.log('limitations found')
        //             if (room.schedule[selectedInterval].limitations.days.length===0 || room.schedule[selectedInterval].limitations.days.includes(new Date().getDay())) {
        //                 //console.log('limitations can be applied', startHour, endHour)
        //                 //console.log(new Date(new Date().getTime()+2*60*60*100)>= room.schedule[selectedInterval].limitations.start && new Date(new Date().getTime()+2*60*60*100) <= room.schedule[selectedInterval].limitations.end)
        //                 if (startHour < room.schedule[selectedInterval].limitations.start || endHour > room.schedule[selectedInterval].limitations.end) {
        //                     return true
        //                 }
        //             }
        //         }
        //     }
        // }

        for (const slot of busySlots) {
            const slotStartHour = slot.start;
            const slotEndHour = slot.end;

            if (date.toLocaleDateString() === new Date(slot.date).toLocaleDateString()) {
                if (
                    (startHour >= slotStartHour && startHour < slotEndHour) ||
                    (endHour > slotStartHour && endHour <= slotEndHour) ||
                    (slotStartHour >= startHour && slotStartHour < endHour) ||
                    (slotEndHour > startHour && slotEndHour <= endHour)
                ) {
                    return true;
                }
            }
        }

        return false;
    };
    const isBusyTime = (timeInterval: string, date: Date): boolean => {
        // Check if the provided time interval overlaps with any busy time slot
        // const [startHour, endHour] = timeInterval.split('-').map((hour) => parseInt(hour));
        // const busySlots = bookedSlots.filter((slot) => new Date(slot.date).toDateString() === date.toDateString());
        // for (const slot of busySlots) {
        //     if (
        //         (startHour <= endHour && startHour <= slot.end && endHour >= slot.start) ||
        //         (startHour > endHour && (startHour <= slot.end || endHour >= slot.start))
        //     ) {
        //         return true;
        //     }
        // }
        return false;
    };

    const toggleCalendar = () => {
        setShowCalendar(old=>!old)
    }

    useEffect(() => {
        generateDates();
        generateTimeIntervals();
    }, [selectedInterval]);

    useEffect(() => {
        if (preselected && preselected.length > 0 && preselected[0].date) {
            const updatedPreselected = [...preselected];
            updatedPreselected[0] = { ...updatedPreselected[0], date: new Date(preselected[0].date) };
            setSelectedHours(updatedPreselected); // Обновите selectedHours с данными из preselected
        }
    }, [preselected]);

    useEffect(()=>{
        if (hours) {
            setSelectedInterval(hours.toString())
        }
        if (date) {
            setCurrentDate(date)
        } else {
            setCurrentDate(`${new Date().getDate()}.${(new Date().getMonth() + 1).toString().padStart(2, '0')}.${new Date().getFullYear()}`)
        }
    }, [])

    const handleScroll = (event: any) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
        setShowGradient(!isScrolledToBottom);
    };

    useEffect(() => {

        const hoursTableContainer = hoursTableRef.current;
        if (hoursTableContainer) {
            if (hoursTableContainer.scrollHeight > 100 && !showGradient) {
                hoursTableContainer.classList.add(styles['scroll-fade']);
            } else {
                hoursTableContainer.classList.remove(styles['scrollable-container']);
            }
        }
    }, [dates, timeIntervals, selectedHours, showGradient]);

    const hoursCount = selectedHours.reduce((totalHours, interval) => {
        const startHour = interval.start;
        const endHour = interval.end === 0 ? 24 : interval.end;
        const hourDiff = endHour >= startHour ? endHour - startHour : 24 - startHour + endHour;
        return totalHours + hourDiff;
    }, 0);

    useEffect(()=>{
        //setSelectedHours([])
        // if (room && room.minHours) {
        //     setSelectedInterval(room.minHours.toString())
        //     if (room.defaultHours && room.defaultHours > room.minHours) {
        //         setSelectedInterval(room.defaultHours.toString())
        //     }
        // }
    }, [room])

    const mergeTimeSlots = (slots: any[]) => {
        const sortedSlots = slots.sort((a, b) => {
            if (a.date < b.date) {
                return -1;
            }
            if (a.date > b.date) {
                return 1;
            }
            if (a.start < b.start) {
                return -1;
            }
            if (a.start > b.start) {
                return 1;
            }
            return 0;
        });

        const mergedSlots = [];
        let currentSlot = sortedSlots[0];

        for (let i = 1; i < sortedSlots.length; i++) {
            const nextSlot = sortedSlots[i];

            // Check if the currentSlot and nextSlot are adjacent and have the same date.
            if (areAdjacent(currentSlot, nextSlot) && currentSlot.date === nextSlot.date) {
                currentSlot.end = nextSlot.end; // Extend the currentSlot's end time.
            } else {
                mergedSlots.push({ ...currentSlot }); // Push a copy of the currentSlot to the mergedSlots array.
                currentSlot = { ...nextSlot }; // Make a copy of the nextSlot to start a new merged slot.
            }
        }

        mergedSlots.push({ ...currentSlot }); // Push the last merged slot.
        console.log(mergedSlots)
        return mergedSlots;
    };

    const areAdjacent = (slot1: { end: any; }, slot2: { start: any; }) => {
        return slot1.end === slot2.start;
    };

    function bookSlots() {
        //console.log(mergeTimeSlots(selectedHours))
         callback(mergeTimeSlots(selectedHours),parseInt(room.price) * parseInt(selectedInterval, 10) *selectedHours.length)
    }

    function calculateOrderCost() {
        let cost = parseFloat(room.price) * parseInt(selectedInterval, 10)
        if (user && user.discount && user.discount > 0) {
            return Math.floor((cost - (cost * user.discount / 100))*100) / 100
        }
            return (Math.floor(cost*100))/100

    }

    // @ts-ignore
    // @ts-ignore
    return (
        <div className={styles['select-book-time-window']}>
            <div className={'row justify-content-between mb-20'}>
                <div className={'row gap-20 align-items-center'}>
                    {user && user.discount && user.discount > 0 ? (
                        <>
      <span className={'text-price'}>
        {Math.floor((parseFloat(room.price) - parseFloat(room.price) * user.discount / 100) * 100) / 100} {room.priceSuffix}
      </span>
                            <span className={'old-price crossline'}>{room.price} {room.priceSuffix}</span>
                        </>
                    ) : (
                        <span className={'text-price'}>
      {room.price} {room.priceSuffix}
    </span>
                    )}
                </div>
                <span className={'blue-text pointer'} onClick={()=>navigation('/spots')}>More rooms</span>
            </div>
            <div className={`${styles['select-time-select']} row align-items-center pa-5 justify-content-between relative`}>
                <div className={`col-12 row align-items-center gap-10 justify-content-between pr-10`}>
                    <div className={'row align-items-center  gap-10 '} onClick={toggleCalendar}>
                        <CalendarIcon/>
                        <p>{currentDate}</p>
                    </div>
                    {!openCalendarInfo && <InfoIcon onClick={() => setOpenCalendarInfo((old: any) => !old)}/>}
                    {openCalendarInfo && (<CloseIcon onClick={() => setOpenCalendarInfo((old: any) => !old)} />)}
                    {openCalendarInfo && (
                        <div className={styles.popup} style={{zIndex: 111, right: 0, top: 40}}>
                            <span className={'text-black main-text text-align-center'}>Choose number of hours</span>
                        </div>
                    )}
                    {showCalendar && (
                        <Calendar dark={true} handleCancelButton={toggleCalendar} handleDateSelect={(date)=> {
                            setCurrentDate(date)
                            toggleCalendar()
                        }} />
                    )}
                </div>
                <div className={`${styles['v-divider']}`} />
                <div className={`col-12 ml-5 row align-items-center gap-20 justify-content-between relative`} >
                    <div className={`row align-items-center justify-content-between col-24`}>
                        <div className={'row align-items-center  gap-10 '} onClick={openSelect}>
                            <ClockIcon />
                            <p>{selectedInterval} hour</p>
                        </div>
                        {!openHoursInfo && <InfoIcon onClick={() => setOpenHoursInfo((old: any) => !old)}/>}
                        {openHoursInfo && <CloseIcon onClick={() => setOpenHoursInfo((old: any) => !old)}/>}
                        {openHoursInfo && (
                            <div className={styles.popup} style={{zIndex: 111}}>
                                <span className={'text-black main-text text-align-center'}>Select number of hours</span>
                            </div>
                        )}
                    </div>
                    {/*<ChevronDoun className={`${styles['pointer']}`} />*/}
                </div>
                {selectOpen && (
                    <div className={`col-12 col justify-content-center gap-20 ${styles['hours-select']} `} ref={hoursTableRef}>
                        {(!room.minHours || room.minHours <  2) && (
                            <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('1'); setSelectOpen(false); setSelectedHours([]); socket.emit('clear by id')}}>1 hour</span>
                        )}
                        {room && room.minHours && room.minHours < 3 && (
                            <>
                                <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('2'); setSelectOpen(false); setSelectedHours([]); socket.emit('clear by id')}}>2 hours</span>
                                <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('3'); setSelectOpen(false); setSelectedHours([]); socket.emit('clear by id')}}>3 hours</span>
                            </>
                        )}

                        {room && room.minHours && room.minHours === 3 && (
                                <span className={`pointer ${styles['list-item']}`} onClick={() => { setSelectedInterval('3'); setSelectOpen(false); setSelectedHours([]); socket.emit('clear by id')}}>3 hours</span>
                        )}

                    </div>
                )}
            </div>
            {
                selectedHours.length > 0 ? (
                    user && user.discount && user.discount > 0 ? (
                        <div className={'col align-items-center'}>
                            {mode !== 'edit' && (
                                <span className={'magenta-text spot-title'}>
                                    {`Total cost ${Math.floor(((parseFloat(room.price) * parseInt(selectedInterval, 10) * selectedHours.length) - (parseFloat(room.price) * parseInt(selectedInterval, 10) * selectedHours.length * user.discount / 100)) * 100) / 100}€ for ${selectedHours.length * parseInt(selectedInterval, 10)} hours`}
                                </span>
                            )}
                            <div className={'red-button row align-content-center justify-content-center mt-10 pointer wrap  mb-20'} onClick={bookSlots}>
                                <span className={'red-button-text'}>{buttonText}</span>
                            </div>
                        </div>
                    ) : (
                        <div className={'col align-items-center'}>
                <span className={'magenta-text spot-title'}>
                    {`Total cost ${parseFloat(room.price) * parseInt(selectedInterval, 10) * selectedHours.length}€ for ${selectedHours.length * parseInt(selectedInterval, 10)} hours`}
                </span>
                            <div className={'red-button row align-content-center justify-content-center mt-10 pointer wrap  mb-20'} onClick={bookSlots}>
                                <span className={'red-button-text'}>Book</span>
                            </div>
                        </div>
                    )
                ) : (<></>)
            }
            <div className={styles['scrollable-container']} onScroll={handleScroll}>
                <div className={`row justify-content-start`} style={{position: 'sticky', top: 0, zIndex: 2, background: '#252730'}}>
                    <div className={styles['border']}  style={{maxWidth: '100px', minWidth: '80px', position: 'sticky', left: 0, zIndex: 2, background: '#252730'}} />
                    {dates.map((dateItem) => (
                        <div className={styles['border']} key={dateItem.formatted} style={{maxWidth: '100px', minWidth: '75px', position: 'sticky', top: 1, zIndex: 1, background: '#252730'}}>
                            <p>{dateItem.formatted}</p>
                            <p className={'text-10'}>{dateItem.dw}</p>
                        </div>
                    ))}
                </div>
                {timeIntervals.map((timeInterval) => (
                    <div className={`row`} key={timeInterval}>
                        <div className={styles['border']}  style={{maxWidth: '100px', minWidth: '80px', position: 'sticky', left: 0, zIndex: 1, background: '#252730'}}>
                            <p>{timeInterval}</p>
                        </div>
                        {dates.map((dateItem) => (
                            <div
                                className={`row align-items-center justify-content-center  ${styles['border-body']} ${inSelected(timeInterval, dateItem.clear)}`}
                                key={dateItem.formatted}
                                style={{ maxWidth: '100px', minWidth: '75px', background: isBusyInterval(timeInterval, dateItem.clear) ? '#252730' : '' }}
                                onClick={() => {
                                    if (!isBusyInterval(timeInterval, dateItem.clear) && !isBusyTime(timeInterval, dateItem.clear)) {
                                        selectSlot(timeInterval, dateItem.clear)
                                    }
                                }}
                            >
                                {isBusyInterval(timeInterval, dateItem.clear) ? <div /> : isBusyTime(timeInterval, dateItem.clear) ? <div className={'row align-items-center'} /> : <div className={`${styles['free']} pointer`}>€{calculateOrderCost()}</div>}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            {showPopup && (
                <PopupComponent buttonText1={'Ok'} onButton1Click={()=>setShowPopup(false)} title={'Incorrect booking'} text={showPopupText} onClose={()=>setShowPopup(false)} />
            )}
            {/*<Button className={`mt-4`} text={`Select ${hoursCount} hour(s)`} callback={selectHours} />*/}
        </div>
    );

};

export default MyFunctionalComponent;
export {};
