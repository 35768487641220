import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import {selectBuildingById, selectRoomById} from "../../../reducers/buildingSlice";
import {clearBooking, Slot} from '../../../reducers/bookingSlice';
import {ReactComponent as CalendarIconSea} from "../../../assets/images/svg/CalendarIconSea.svg";
import {ReactComponent as ClockIconSea} from "../../../assets/images/svg/ClockIconSea.svg";
import styles from "../../../assets/scss/BuildingPage.module.scss";
import DynamicIcon from "../../../components/common/DynamicIcon.component";
import {ReactComponent as RoomIcon} from '../../../assets/images/svg/RoomSeaIcon.svg';
import {ReactComponent as PinSea} from '../../../assets/images/svg/PinSea.svg';
import DraftComponent from "../../../components/common/Draft/Draft.component";
import axios from "axios";
import TopBar from "../TopBar/TopBar";
import {verify} from "crypto";
import PopupComponent from "../../common/Popup/Popup.component";
import InputMask from "react-input-mask";

const IncludesSection = (props: {includes: any[]}) => {
    return (
        <div className={'row wrap gap-20 mt-20'}>
            {props.includes.map((item: any)=>(
                <div className={`row align-items-center gap-20 ${styles['include-item']}`}>
                    <DynamicIcon iconName={item.icon} />
                    <span>{item.name}</span>
                </div>
            ))}
        </div>

    )
}

const PersonalInformation = (props: {userData: any, change: any}) => {
    const [userData, setUserData] = useState<any>(null)
    useEffect(()=>{
        setUserData(props.userData)
    }, [])

    useEffect(()=>{
        props.change(userData)
    }, [userData])

  return (
      <div className={'col gap-10'}>

          <div className={'booking-input-wrapper col'}>
              <span>Name</span>
              <input className={'booking-input'} placeholder={'Enter your name'} value={userData?.name}
                     disabled={userData?.name && userData.id}
                     onChange={(e) => setUserData((old: any) => ({...old, name: e.target.value}))}/>
          </div>
          <div className={'booking-input-wrapper col'}>
              <span>Last name</span>
              <input className={'booking-input'} placeholder={'Enter your last name'} value={userData?.lastName}
                     disabled={userData?.lastName && userData.id}
                     onChange={(e) => setUserData((old: any) => ({...old, lastName: e.target.value}))}/>
          </div>
          <div className={'booking-input-wrapper col'}>
              <span>Phone</span>
              <InputMask
                  mask="+99999999999"
                  className={'booking-input'} value={userData?.phone} disabled={userData?.phone && userData.id}
                  onChange={(e) => setUserData((old: any) => ({...old, phone: e.target.value}))}>
              </InputMask>
          </div>
          <div className={'booking-input-wrapper col'}>
              <span>E-mail</span>
              <input className={'booking-input'} placeholder={'Enter your e-mail'} value={userData?.email}
                     disabled={userData?.email && userData.id}
                     onChange={(e) => setUserData((old: any) => ({...old, email: e.target.value}))}/>
          </div>
          <span className={'magenta-text'}>Please enter valid contacts to receive your room access code</span>
          <span>Booking confirmation will be sent to this address</span>
      </div>
  )
}


const ConfirmationPage = () => {
    const [crumbs, setCrumbs] = useState<any[]>([
        {
            text: 'Main',
            link: '/'
        },
        {
            text: 'Studios',
            link: '/spots'
        }
    ])
    const [amountWithDiscount, setAmountWithDiscount] = useState<number>(0)
    const [amount, setAmount] = useState<number>(0)
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const booking = useSelector((state: RootState) => state.booking)
    // @ts-ignore
    const room = useSelector(selectRoomById(booking.roomID))
    const user = useSelector((state: RootState)=>state.auth.userFull)
    const building = useSelector(selectBuildingById(booking.buildingID))
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [newUserData, setNewUserData] = useState<any>(null)
    const [validationError, setValidationError] = useState<string>('')
    const [promocode, setPromocode] = useState<string>('')
    const [promocodeId, setPromocodeId] = useState<any>(null)
    const [promoStatus, setPromoStatus] = useState<string>('empty')

    const [countdown, setCountdown] = useState(300); // 10 minutes in seconds

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(prevCountdown => {
                console.log(new Date().getTime() - booking.date.getTime())
                if (new Date().getTime() - booking.date.getTime() < 300000) {
                    return prevCountdown - 1;
                } else {
                    clearInterval(interval);
                    window.location.href = '/rooms/'+room._id; // Redirect to the booking page after the countdown
                    return 0;
                }
            });
        }, 1000); // Update countdown every second

        return () => clearInterval(interval); // Cleanup: clear interval on unmount
    }, []);

    useEffect(() => {
        if (booking?.slots?.length === 0) {
            navigation('/404')
        } else {
            setCrumbs([
                {
                    text: 'Main',
                    link: '/'
                },
                {
                    text: 'Studios',
                    link: '/spots'
                },
                {
                    text: room.title,
                    link: `/room/${room.id}`
                },
                {
                    text: 'Booking',
                    link: `/confirmation`
                }
            ])
            calculateAmount()
        }

    }, [])

    useEffect(()=>{
        calculateAmount()
    }, [promocodeId])

    // function calculateAmount() {
    //     if (!room) {
    //         console.error('Room with the provided ID not found.');
    //         return 0;
    //     }
    //
    //     let totalHours = 0;
    //
    //     // Calculate the total hours in all slots
    //     booking.slots.forEach((slot) => {
    //         const startHour = slot.start;
    //         const endHour = slot.end === 0 ? 24 : slot.end; // Handle midnight (00)
    //         totalHours += endHour > startHour ? endHour - startHour : 24 - startHour + endHour;
    //     });
    //
    //     // Calculate the total amount based on the room price and total hours
    //     const amount = room.price * totalHours;
    //
    //     return amount;
    // }

    function mergeSlotsByDate(slots: Slot[]) {
        const mergedSlots: any = {};
        for (const slot of slots) {
            // @ts-ignore
            const dateWithoutTime = slot.date.toISOString().split('T')[0];
            if (mergedSlots[dateWithoutTime]) {
                mergedSlots[dateWithoutTime].slots.push(slot);
            } else {
                mergedSlots[dateWithoutTime] = { date: dateWithoutTime, slots: [slot] };
            }
        }
        return Object.values(mergedSlots);
    }

    const formatTimeToAMPM = (hours: number): string => {
        const isPM = hours >= 12;
        const isMidnight = hours === 0;
        const hourInAMPMFormat = isMidnight ? 12 : hours % 12;

        return `${hourInAMPMFormat < 10 ? '0' : ''}${hourInAMPMFormat} ${isPM ? 'pm' : 'am'}`;
    };

    async function calculateAmount() {

        const answer = await axios.post('https://gscoworking.com/api/common/order/cost', {data: booking, promocode: {id: promocodeId, status: promoStatus}, user})
        setAmount(answer.data.amount)
        setAmountWithDiscount(answer.data.discounted)
    }

    function verifyUserData() {
        const nameInput = document.querySelector('.booking-input-wrapper:nth-child(1) input') as HTMLInputElement;
        const lastNameInput = document.querySelector('.booking-input-wrapper:nth-child(2) input') as HTMLInputElement;
        const phoneInput = document.querySelector('.booking-input-wrapper:nth-child(3) input') as HTMLInputElement;
        const emailInput = document.querySelector('.booking-input-wrapper:nth-child(4) input') as HTMLInputElement;

        const name = nameInput.value.trim();
        const lastName = lastNameInput.value.trim();
        const phone = phoneInput.value.trim();
        const email = emailInput.value.trim();
        if (name === '') {
            setValidationError('Please enter your name.');
            setShowPopup(true)
            return false;
        }

        if (lastName === '') {
            setValidationError('Please enter your last name.');
            setShowPopup(true)

            return false;
        }

        if (phone === '') {
            setValidationError('Please enter your phone number.');
            setShowPopup(true)

            return false;
        }

        if (email === '') {
            setValidationError('Please enter your email.');
            setShowPopup(true)

            return false;
        }

        // Email pattern validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setValidationError('Please enter a valid email address.');
            setShowPopup(true)

            return false;
        }

        return true; // All fields are filled and email is valid
    }

    function handleUserDataChange(userData: any) {
        setNewUserData(userData)
    }

    function placeOrder() {
        if (!user || !user._id) {
            if (!verifyUserData()) {
                return
            }
        }
        if (!booking.userID) {
            const bookingObject = {...booking, user: {
                name: newUserData.name,
                lastName: newUserData.lastName,
                phone: newUserData.phone,
                email: newUserData.email
                }}
            navigation('/payment', {state: {data: bookingObject, room: room, building: building, promocode: {id: promocodeId, status: promoStatus}}})
            return;
        }
        navigation('/payment', {state: {data: booking, room: room, building: building, promocode: {id: promocodeId, status: promoStatus}}})
    }

    function checkPromocode() {
        if (!promocode) {
            return
        }
        try {
            axios.post(`https://gscoworking.com/api/promo-codes/apply/${promocode}`, {room: booking.roomID, building: building.userID}).then((answer)=>{
                if (answer.data.success) {
                    setPromoStatus('applied')
                    setPromocodeId(answer.data.codeId)
                } else {
                    setPromoStatus('wrong')
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <TopBar MiddlePart={'Booking'} backLink={`/rooms/${room?._id}`} />
            {room && building && (
                <div className={'wrapper col gap-30'}>
                    <div className={'col'}>
                        <div className={'relative col-24'}>
                            <div className={'col col-24 pa-0 ml-0'} style={{transform: `scale(0.64)`, position: 'absolute', top: -50, left: -65}}>
                                <DraftComponent interactive={false} id={building.id} popupText={''} selected={room._id}/>
                            </div>
                        </div>
                        <div className={'col col-24 gap-20 mt-250'}>
                            <span className={'spot-title'}>Order confirmation</span>
                            <div className={'row wrap gap-30'}>
                                {mergeSlotsByDate(booking.slots).map((slot: any)=>(
                                    <div className={'col gap-30'}>
                                        <div className={'row gap-15'}>
                                            <CalendarIconSea />
                                            <span>{new Date(slot.date).toDateString()}</span>
                                        </div>
                                        <div className={'row gap-15'}>
                                            <ClockIconSea />
                                            <div className={'col'}>
                                                {slot.slots.map((time: any)=> (
                                                    <span>{formatTimeToAMPM(time.start)} – {formatTimeToAMPM(time.end)}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className={'col'}>
                                    <div className={'row mt-10 gap-10 mb-20'}>
                                        <PinSea />
                                        <span className={'review-address'}>{room.address}</span>
                                    </div>
                                    <div className={'row mt-10 gap-10 mb-20'}>
                                        <RoomIcon />
                                        <span className={'review-address'}>{room.title}</span>
                                    </div>
                                </div>
                            </div>
                            {promoStatus === 'empty' && (
                                <div className={styles['promo-wrapper']}>
                                    <input className={styles['promo-wrapper-input']} value={promocode} placeholder={'Promocode here'} onChange={(e)=>{setPromocode(e.target.value)}} />
                                    <span className={'pointer text-black'} onClick={()=>checkPromocode()} >Apply</span>
                                </div>
                            )}
                            {promoStatus === 'applied' && (
                                <div className={'col gap-10'}>
                                    <div className={styles['promo-wrapper-applied']}>
                                        <input disabled={true} value={promocode} className={styles['promo-wrapper-input-applied']} placeholder={'Promocode here'} onChange={(e)=>{setPromocode(e.target.value)}} />
                                        <span className={'pointer blue-text'} onClick={()=> {
                                            setPromoStatus('empty')
                                            setPromocode('')
                                            setPromocodeId(null)
                                        }}>Delete</span>
                                    </div>
                                    <span className={'text-10'} style={{color: '#525460'}}>Promocode applied</span>
                                </div>
                            )}
                            {promoStatus === 'wrong' && (
                                <div className={'col gap-10'}>

                                    <div className={styles['promo-wrapper-wrong']}>
                                        <input disabled={true} value={promocode} className={styles['promo-wrapper-input-wrong']} placeholder={'Promocode here'} onChange={(e)=>{setPromocode(e.target.value)}} />
                                        <span className={'pointer blue-text'} onClick={()=> {
                                            setPromoStatus('empty')
                                            setPromocode('')
                                            setPromocodeId(null)
                                        }}>Delete</span>
                                    </div>
                                    <span className={'text-10'} style={{color: '#D53B5C'}}>Incorrect promocode</span>

                                </div>

                            )}
                            {user && user.discount && (
                                <span className={'sea-text text-14'}>You personal discount is {user.discount}%</span>
                            )}
                            <div className={'row align-items-center  gap-80 mt-0'}>
                                <span className={'spot-subtitle'}>Total:</span>
                                <div className={'row align-items-center gap-20'}>
                                    {user && user.discount && (
                                        <span className={'sea-text text-18 crossline'}>€{amount}</span>
                                    )}
                                    <span className={'magenta-text h2-text'}>€{amountWithDiscount===0 ? '0' : amountWithDiscount}</span>
                                </div>
                            </div>
                            <span className={'h2-text mt-30'}>Resident rules:</span>
                            <p className={'text-main my-0'}>The owner asks you to accept the rules of residence:</p>
                            <ul className={' my-0'}>
                                <li>No smoking</li>
                                <li>Pets not allowed</li>
                            </ul>
                            <p className={'main-text my-0'}>By proceeding to the next page, you accept these terms.</p>
                            <p className={'main-text  my-0'}>Session lasts 55 min, every last 5 minutes goes for cleaning by the studio staff</p>

                            <span className={'spot-subtitle mt-30'}>Personal information: </span>
                            <PersonalInformation change={handleUserDataChange} userData={{id: user?._id, name: user?.name, lastName: user?.lastName, email: user?.email, phone: user?.phone}} />
                        </div>
                        <div className={'col-24 col'}>
                            {/*<img src={room.image} className={'br-10'}/>*/}
                            {/*<span className={'spot-subtitle mt-12'}>{room.title}</span>*/}
                            {/*<div className={'row mt-16 gap-10'}>*/}
                            {/*    <RatingStar />*/}
                            {/*    <span>{room.rating}</span>*/}
                            {/*    <span className={'review-address'}>{room.reviews.length} reviews</span>*/}
                            {/*</div>*/}

                            <div className={'red-button row align-content-center justify-content-center mt-36 pointer wrap'} onClick={placeOrder}>
                                <span className={'red-button-text'}>Book </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <PopupComponent title={'Something went wrong try again'} text={`${validationError}`} onClose={()=>setShowPopup(false)} />
            )}
        </>
    );
};

export default ConfirmationPage;
export {};
