import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../reducers/types";
import {useLocation, useNavigate} from "react-router-dom";
import PrivateMenuComponent from "../components/desktop/Private/PrivateMenu.component";
import authSlice, {checkLocalStorageForLoggedInStatus, logout} from "../reducers/authSlice";
import PrivateInfoWindowComponent from "../components/desktop/Private/PrivateInfoWindow.component";

const PrivatePage = () => {
    const user = useSelector((state: RootState)=>state.auth.user)
    const navigation = useNavigate()
    const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0)
    const dispatch = useDispatch()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramValue:any = searchParams.get('tab');
    function handleLogout () {
        try {
            dispatch(logout())
            console.log('logouted')
        } catch (e: any) {

        }
        window.location.href = '/'
    }

    function selectMenuItem(idx: number) {
        setSelectedMenuItem(idx)
    }

    useEffect(()=>{
        if (!user || !user._id) {
            navigation('/')
        }
        if (parseInt(paramValue) > 0) {
            selectMenuItem(parseInt(paramValue))
        }
    }, [])

    return (
        <div className={'row gap-45'}>
            <PrivateMenuComponent selected={selectedMenuItem} handleMenuItemClick={selectMenuItem}  handleLogout={handleLogout}/>
            {user && user._id && (
                <PrivateInfoWindowComponent  selectedMenu={selectedMenuItem} userId={user._id} setMenu={(x: number)=>setSelectedMenuItem(x)}/>
            )}
        </div>
    );
};

export default PrivatePage;
export {};
