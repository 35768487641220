import React, {useEffect, useState} from 'react';
import styles from "../../desktop/Private/PrivateInfoWindow.module.scss";
import {ReactComponent as Visa} from "../../../assets/images/svg/Visa.svg";
import {ReactComponent as MasterCard} from "../../../assets/images/svg/MasterCars.svg";
import {ReactComponent as Selected} from "../../../assets/images/svg/CheckboxSelected.svg";
import {ReactComponent as NotSelected} from "../../../assets/images/svg/CheckboxNotSelected.svg";
import {ReactComponent as AddNewCard} from "../../../assets/images/svg/AddCard.svg";
import {ReactComponent as ChevroneRight} from "../../../assets/images/svg/ChevronRight.svg";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers/types";
import axios from "axios";
import {checkLocalStorageForLoggedInStatus, fetchUserDataByIdAndDispatch} from "../../../reducers/authSlice";
import TopBar from "../TopBar/TopBar";
import InputMask from "react-input-mask";
import {ReactComponent as CopyIcon} from "../../../assets/images/svg/CopyWhite.svg";
import {useNavigate} from "react-router-dom";

const PersonalInfoPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [fieldsDisabled, setFieldsDisabled] = useState({
        name: true,
        lastName: true,
        phone: true,
        dateOfBirth: true
    })
    const fromStore = useSelector((state: RootState)=>state.auth.userFull)
    const [userObject, setUserObject] = useState<any>(null)
    const initialCardNumber = '____ ____ ____ ____'; // Use underscores (_) as placeholders
    const [referal, setReferal] = useState<any>(null)

    const [editPayment, setEditPayment] = useState(false)
    const [addPayment, setAddPayment] = useState(false)
    const [showAllCards, setShowAllCards] = useState<boolean>(false)
    const [displayedCards, setDisplayedCard] = useState<any[]>([])
    const [newBind, setNewBind] = useState({
        cardType: '',
        cardNumber: initialCardNumber,
        cardHolder: '',
        cardCVC: '',
        expirationDate: '',
        mainCard: false
    })
    const [certificate, setCertificate] = useState<string>('')
    const [certificateMessage, setCertificateMessage] = useState<any>({
        status: 'success',
        message: ''
    })
    useEffect(() => {
        if (fromStore) {
            setUserObject(fromStore);
            if (fromStore.referalCode) {
                axios.get('https://gscoworking.com/api/promo-codes/get-code/' + fromStore.referalCode).then((r: any) => {
                    setReferal(r.data)
                })
            }
        }
    }, [fromStore]);
    async function handleUserUpdate () {
        try {
            // Prepare the updated user data
            const updatedUserData = {
                name: userObject.name,
                lastName: userObject.lastName,
                phone: userObject.phone,
                dateOfBirth: userObject.dateOfBirth
                // Include other fields you want to update
            };
            console.log(userObject)
            // Make the API request to update the user
            const response = await axios.put(`https://gscoworking.com/api/user/${userObject._id}`, updatedUserData);

            // If the update is successful, update the state with the updated user data
            // @ts-ignore
            dispatch(checkLocalStorageForLoggedInStatus())
            setFieldsDisabled({
                name: true,
                lastName: true,
                phone: true,
                dateOfBirth: true
            })
        } catch (error) {
            console.error('Error updating user:', error);
            // Handle the error (show an error message, etc.)
        }
    }

    async function setMainCard(selectedCardNumber: string) {
        const updatedData = userObject.paymentMethods.map((card: any) => ({
            ...card,
            mainCard: card.cardNumber === selectedCardNumber,
        }));
        setUserObject((old: any)=>({...old, paymentMethods: updatedData}))
        const response = await axios.put(`https://gscoworking.com/api/user/${userObject._id}`, {paymentMethods: updatedData});

        // If the update is successful, update the state with the updated user data
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus())
    }

    const getCardTypeByNumber = (cardNumber: string) => {
        const cardTypes = [
            { type: 'Visa', pattern: /^4/ },
            { type: 'MasterCard', pattern: /^5[1-5]/ },
            { type: 'American Express', pattern: /^(34|37)/ },
            { type: 'Discover', pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|2[0-5][0-9])|64[4-9]|65)/ },
            { type: 'Diners Club - Carte Blanche', pattern: /^(30[0-5]|3095)/ },
            { type: 'Diners Club - International', pattern: /^(36|38)/ },
            { type: 'Diners Club - USA & Canada', pattern: /^(54|55)/ },
            { type: 'JCB', pattern: /^35/ },
            { type: 'UnionPay', pattern: /^62/ },
            { type: 'MIR', pattern: /^2200/ },
        ];

        const sanitizedNumber = cardNumber.replace(/\D/g, ''); // Remove non-digit characters

        for (const cardType of cardTypes) {
            if (sanitizedNumber.match(cardType.pattern)) {
                return cardType.type;
            }
        }

        return 'Unknown'; // If the card type is not recognized
    };
    async function applyCertificate() {
        console.log({cert: certificate, userId: userObject._id})
        let answer = await axios.post('https://gscoworking.com/api/cert/apply', {code: certificate, userId: userObject._id}).catch((e:any)=>{
            console.log(e.message)
            setCertificateMessage({status: 'error', message: `Can't apply certificate`})
        })
        if (answer && answer.data) {
            console.log(answer.data)
            if (answer.data.success)  {
                setCertificateMessage(
                    {message: `${answer.data.message.amount}€ certificate has been successfully activated`, status: 'success'})
                // @ts-ignore
                dispatch(fetchUserDataByIdAndDispatch(userObject._id));
            } else {
                setCertificateMessage({status: 'error', message: `Can't apply certificate`})
            }
        }
    }
    async function bindNewCard() {
        const newCard = {
            cardNumber: newBind.cardNumber.slice(-4),
            cardType: getCardTypeByNumber(newBind.cardNumber),
            mainCard: false
        }
        const updatedData = [...userObject.paymentMethods, newCard];
        const response = await axios.put(`https://gscoworking.com/api/user/${userObject._id}`, {paymentMethods: updatedData});
        setEditPayment(false)
        setAddPayment(false)
        setNewBind({
            cardType: '',
            cardNumber: initialCardNumber,
            cardHolder: '',
            cardCVC: '',
            expirationDate: '',
            mainCard: false
        })
        // @ts-ignore
        dispatch(checkLocalStorageForLoggedInStatus())
    }
    const handleInputChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;

        // Remove any non-digit characters from the input
        const digitsOnly = value.replace(/\D/g, '');

        // Ensure the number is limited to a maximum of 16 digits
        const truncatedDigits = digitsOnly.slice(0, 16);

        // Split the digits into groups of four
        const groups = truncatedDigits.match(/(\d{1,4})/g);

        // Join the groups with spaces to create the formatted value
        const formattedValue = groups ? groups.join(' ') : '';
        setNewBind(old=>({...old, cardNumber: formattedValue}));
    };

    const handleExpirationInputChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;

        // Remove any non-digit characters from the input
        const digitsOnly = value.replace(/\D/g, '');

        // Ensure the number is limited to a maximum of 6 digits (MMYYYY)
        const truncatedDigits = digitsOnly.slice(0, 6);

        // Split the digits into groups of two for MM / YYYY format
        const groups = truncatedDigits.match(/^(\d{1,2})(\d{0,4})/);

        // Format the expiration date as "MM / YYYY"
        const formattedValue = groups ? `${groups[1]} / ${groups[2]}` : '';

        setNewBind(old=>({...old, expirationDate: formattedValue}));
    };

    async function copyToClipboard(code: string) {
        try {
            await navigator.clipboard.writeText(code);
        } catch (error) {
            console.error('Не удалось скопировать текст: ', error);
        }
    }

    useEffect(()=>{
        if (userObject && userObject.paymentMethods) {
            let cards = userObject.paymentMethods.filter((x:any)=>x.mainCard)
            if (showAllCards) {
                setDisplayedCard(userObject.paymentMethods)
            } else {
                setDisplayedCard([cards[0]])
            }
        }
    }, [showAllCards, userObject])

    return (
        <div className={'pb-100'}>
            <TopBar MiddlePart={'Personal'} backLink={'/private'} />
            {userObject && (
                <div className={'col col-24 mt-50 gap-40'}>
                    <div className={'col col-24 gap-25'}>
                        <span className={'spot-title'}>Personal information:</span>
                        <div className={'col col-12 gap-10'}>
                            <div className={'booking-input-wrapper col'} >
                                <span>Name</span>
                                <div className={'row align-items-center'}>
                                    <input
                                        className={'booking-input col-22'}
                                        disabled={fieldsDisabled.name}
                                        value={userObject.name}
                                        onChange={(e) => setUserObject((old: any)=>({...old, name: e.target.value}))}
                                    />
                                    {fieldsDisabled.name && (
                                        <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, name: false}))}>Edit</span>
                                    )}
                                </div>
                            </div>
                            <div className={'booking-input-wrapper col'} >
                                <span>Last name</span>
                                <div className={'row align-items-center'}>
                                    <input
                                        className={'booking-input  col-22'}
                                        value={userObject.lastName}
                                        disabled={fieldsDisabled.lastName}
                                        onChange={(e) => setUserObject((old: any)=>({...old, lastName: e.target.value}))}
                                    />
                                    {fieldsDisabled.lastName && (
                                        <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, lastName: false}))}>Edit</span>
                                    )}
                                </div>
                            </div>
                            <div className={'booking-input-wrapper col'} >
                                <span>Phone</span>
                                <div className={'row align-items-center'}>
                                    <InputMask
                                        mask="+99999999999"
                                        disabled={fieldsDisabled.phone}
                                        className={'booking-input col-22'}
                                        maskChar="_"
                                        value={userObject.phone}
                                        placeholder={'phone number'}
                                        onChange={(e) => setUserObject((old: any)=>({...old, phone: e.target.value}))}
                                    >
                                    </InputMask>
                                    {fieldsDisabled.phone && (
                                        <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, phone: false}))}>Edit</span>
                                    )}
                                </div>
                            </div>
                            <div className={'booking-input-wrapper col'} >
                                <span>E-mail</span>
                                <input className={'booking-input'} value={userObject.email} disabled={true}/>
                            </div>
                            <div className={'booking-input-wrapper col'} >
                                <span>Date of Birth</span>
                                <div className={'row align-items-center'}>
                                    <input
                                        type={'date'}
                                        className={'booking-input col-22'}
                                        value={userObject.dateOfBirth ? new Date(userObject.dateOfBirth).toISOString().slice(0, 10) : ''}
                                        disabled={fieldsDisabled.dateOfBirth}
                                        onChange={(e) => setUserObject((old: any)=>({...old, dateOfBirth: e.target.value}))}
                                    />
                                    {fieldsDisabled.dateOfBirth && (
                                        <span className={`pointer col-2 ${styles['edit-text']}`} onClick={()=>setFieldsDisabled(old=>({...old, dateOfBirth: false}))}>Edit</span>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className={'red-button row align-content-center justify-content-center mt-20 pointer'} style={{width: '90vw'}} onClick={handleUserUpdate}>
                            <span className={'red-button-text'}>Save changes</span>
                        </div>
                    </div>
                    <div className={'col col-22'}>
                        {/*<div className={'col'}>*/}
                        {/*    <div className={'row align-items-center justify-content-between mb-21'}>*/}
                        {/*        <span className={'spot-title'}>Payment method:</span>*/}
                        {/*        <span className={`pointer col-2 ${styles['edit-text']}`}>Edit</span>*/}
                        {/*    </div>*/}
                        {/*    {displayedCards.map((method: any)=>(*/}
                        {/*        <div className={'row justify-content-between col-24 mb-20'}>*/}
                        {/*            {method && (*/}
                        {/*                <>*/}
                        {/*                    <div className={'row gap-10 align-items-center'}>*/}
                        {/*                        {method?.cardType.toLowerCase()==='visa' && (*/}
                        {/*                            <Visa />*/}
                        {/*                        )}*/}
                        {/*                        {method?.cardType.toLowerCase()==='mastercard' && (*/}
                        {/*                            <MasterCard />*/}
                        {/*                        )}*/}
                        {/*                        <div className={'row align-items-center gap-5'}>*/}
                        {/*                            <span>{method.cardType}</span>*/}
                        {/*                            <span> • </span>*/}
                        {/*                            <span>{method.cardNumber}</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={'row align-items-center gap-10'}>*/}
                        {/*                        {method?.mainCard && (*/}
                        {/*                            <span className={'text-14 magenta-text'}>main card</span>*/}
                        {/*                        )}*/}
                        {/*                        {method?.mainCard && (*/}
                        {/*                            <Selected className={'pointer'} />*/}
                        {/*                        )}*/}
                        {/*                        {!method?.mainCard && (*/}
                        {/*                            <NotSelected className={'pointer'} onClick={()=>setMainCard(method.cardNumber)}/>*/}
                        {/*                        )}*/}
                        {/*                    </div>*/}
                        {/*                </>*/}
                        {/*            )}*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*    {!showAllCards && !addPayment && (*/}
                        {/*        <span className={'blue-text pointer'}*/}
                        {/*              onClick={() => setShowAllCards(old => !old)}>Show all cards</span>*/}
                        {/*    )}*/}
                        {/*    {showAllCards && (*/}
                        {/*        <span className={'blue-text pointer'} onClick={()=>setShowAllCards(old=>!old)}>Hide cards</span>*/}

                        {/*    )}*/}
                        {/*    {!addPayment && (*/}
                        {/*        <div className={'row justify-content-between col-24 mb-20 pointer'} onClick={()=>setAddPayment(true)}>*/}
                        {/*            <div className={'row gap-10 align-items-center'}>*/}

                        {/*                <AddNewCard />*/}

                        {/*                <div className={'row align-items-center gap-5'} >*/}
                        {/*                    <span>Bind new card</span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'row align-items-center gap-10'}>*/}
                        {/*                <ChevroneRight />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*    {addPayment && (*/}
                        {/*        <div className={'col gap-15 col-22 pa-0'}>*/}
                        {/*            <span className={'spot-title'}>Add new:</span>*/}
                        {/*            <div className={'booking-input-wrapper col'} >*/}
                        {/*                <span>Cardholder's name *</span>*/}
                        {/*                <div className={'row align-items-center'}>*/}
                        {/*                    <input*/}
                        {/*                        className={'booking-input  col-24'}*/}
                        {/*                        value={newBind.cardHolder}*/}
                        {/*                        onChange={(e) => setNewBind((old: any)=>({...old, cardHolder: e.target.value}))}*/}
                        {/*                    />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'booking-input-wrapper col'} >*/}
                        {/*                <span>Card number *</span>*/}
                        {/*                <div className={'row align-items-center'}>*/}
                        {/*                    <input*/}
                        {/*                        className={'booking-input  col-22'}*/}
                        {/*                        value={newBind.cardNumber}*/}
                        {/*                        onChange={handleInputChange}*/}
                        {/*                    />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className={'row gap-10'}>*/}
                        {/*                <div className={'col-11 booking-input-wrapper col '} style={{width: 135}}>*/}
                        {/*                    <span>Validity *</span>*/}
                        {/*                    <div className={'row align-items-center'}>*/}
                        {/*                        <input*/}
                        {/*                            className={'booking-input  col-22'}*/}
                        {/*                            value={newBind.expirationDate}*/}
                        {/*                            onChange={handleExpirationInputChange}*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className={'booking-input-wrapper col col-11'} style={{width: 150}}>*/}
                        {/*                    <span>CVC-code *</span>*/}
                        {/*                    <div className={'row align-items-center'}>*/}
                        {/*                        <input*/}
                        {/*                            className={'booking-input  col-22'}*/}
                        {/*                            maxLength={3}*/}
                        {/*                            type={'password'}*/}
                        {/*                            value={newBind.cardCVC}*/}
                        {/*                            onChange={(e) => setNewBind((old: any)=>({...old, cardCVC: e.target.value}))}*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*    {addPayment && (*/}
                        {/*        <div className={'red-button row align-content-center justify-content-center mt-20 pointer'} style={{width: '90vw'}} onClick={bindNewCard}>*/}
                        {/*            <span className={'red-button-text'}>Bind new card</span>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                        <div className={'col col-20'}>
                            <div className={'col gap-60 col-24'}>
                                <div className={'col gap-10'}>
                                    <div className={'row align-items-center justify-content-between mb-21'}>
                                        <span className={'spot-title'}>Balance:</span>
                                        {/*<span className={`pointer col-2 ${styles['edit-text']}`}>Edit</span>*/}
                                    </div>
                                    {userObject && userObject.fa2 && (
                                        <>
                                            <span className={'text-price text-26 bold sea-text'}>{Math.floor(userObject?.deposit?.totalAmount) || 0} €</span>

                                            <div className={'red-button mt-20 row align-items-center justify-content-center'} style={{border: 'none'}} onClick={()=>navigate('/private/funds')}>
                                                <span className={'red-button-text'}>Add funds</span>
                                            </div>
                                        </>
                                    )}
                                    {!userObject || !userObject.fa2 && (
                                        <span className={'text-14 blue-text pointer'} onClick={()=>navigate('/private/settings')}>Please turn on two-factor authorization</span>
                                    )}

                                </div>

                                {userObject && userObject.discount && (
                                    <div className={styles['discount-container']} style={{backgroundImage: `url(${require('../../../assets/images/discountBlockBackground.png')})`, backgroundPosition: 'right', backgroundRepeat: 'no-repeat'}}>
                                        <span className={'text-16 sea-text'}>My discount</span>
                                        <span className={'text-38 sea-text bold'}>{userObject.discount}%</span>
                                    </div>
                                )}
                                <div className={'col col-20 gap-10'}>
                                    <span className={`spot-title`}>My promocode:</span>
                                    <div className={`mt-20 relative ${styles.promo}`}>
                                        <CopyIcon className={`${styles.copyButton}`} onClick={()=>copyToClipboard(userObject.referalCode)}/>
                                        <span className={`${styles.promocode}`}>{userObject.referalCode}</span>
                                    </div>
                                    {referal && (
                                        <span className={'col-24  sea-text'}>Your referral code: {referal.discount.profit.value}% cashback for you, {referal.discount.value}% off for
                                    others</span>
                                    )}

                                </div>
                                <div className={'col gap-10'}>
                                    <span className={'spot-title'}>Activate certificate:</span>
                                    <div className={`relative row align-items-center justify-content-around ${styles.activateCertificate}`}>
                                        <input className={styles.amountInput} value={certificate} onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            let input = e.target.value.replace(/[^A-Za-z0-9]/g, ''); // Allow letters and digits
                                            let formattedCertificate = '';

                                            for (let i = 0; i < input.length; i++) {
                                                if (i === 4 || i === 8) {
                                                    formattedCertificate += '-';
                                                }
                                                formattedCertificate += input[i];
                                            }

                                            if (formattedCertificate.length > 13) {
                                                // Truncate to "XXXX-XXXX-XXXX"
                                                formattedCertificate = formattedCertificate.slice(0, 14);
                                            }
                                            console.log(formattedCertificate.toUpperCase())
                                            setCertificate(formattedCertificate.toUpperCase())}} />
                                        <span className={`pointer ${styles.applyBtn}`} onClick={applyCertificate}>Apply</span>
                                    </div>
                                    {certificateMessage && certificateMessage.status==='success' && (
                                        <span className={'text-14 sea-text'}>{certificateMessage.message}</span>
                                    )}
                                    {certificateMessage && certificateMessage.status==='error' && (
                                        <span className={'text-14 magenta-text'}>{certificateMessage.message}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};

export default PersonalInfoPage;
export {};
